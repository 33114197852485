import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styles from './ErrorMessage.module.css';
import errorImg from '../../styles/Images/createAppointment/form/errorImg.png';

const ErrorMessage = ({ message, isHidden, isHalfInput = false }) => {
    const { t } = useTranslation();
    const lng = useSelector((state) => state.config.lng);

    return <div className={isHidden ? styles.containerHidden : styles.container}>
        <div><img src={errorImg} alt="errorImg" className={lng === "ar" ? styles.iconErrorRTL : styles.iconErrorLTR}></img></div>
        <div className={styles.iconEditTitle}><p className={isHalfInput ? lng === "ar" ? styles.titleErrorHalfInputRTL : styles.titleErrorHalfInputLTR : lng === "ar" ? styles.titleErrorRTL : styles.titleErrorLTR}>{t(message)}</p></div>
    </div>;
};

export default React.memo(ErrorMessage);