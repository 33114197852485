import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './SimpleDropDown.module.css';
import TitleSection from '../TitleSection/TitleSection';
import ErrorMessage from './../../UI/ErrorMessage/ErrorMessage';

const SimpleDropDown = ({ options, title, handleChange, name, handleRef, subTitle, returnValue = false, preselectedValue, isEmptyValue = false, validationError = false }) => {

  const { t } = useTranslation();
  const lng = useSelector((state) => state.config.lng);
  const [selectedValue, setSelectedValue] = useState(isEmptyValue ? '' : preselectedValue);
  const [errorMsg, setErrorMsg] = useState('');
  const [inputIsHidden, setInputIsHidden] = useState(true);

  useEffect(() => {
    if (name === 'time') {
      if (preselectedValue) {
        setSelectedValue(preselectedValue);
      }
      else if (options && options.length > 0) {
        setSelectedValue(options[0]);
      }
    }
  }, [preselectedValue, isEmptyValue]);

  useEffect(() => {
    if (validationError && !selectedValue) {
      setErrorMsg('Please select one of the options above');
      setSelectedValue(undefined);
      setInputIsHidden(false);
    } else {
      setErrorMsg('');
      setInputIsHidden(true);
    }
  }, [validationError, selectedValue]);

  const handleOptionChange = event => {
    const selectedValue = event.target.value;
    setSelectedValue(selectedValue);
    let result = (returnValue === true ? options.find(option => option.value === selectedValue) : selectedValue);
    handleChange && handleChange(name, result);
  };

  return <div className={styles.container}>
    {title.length > 0 && <TitleSection title={title} subTitle={subTitle} />}
    <select className={lng === "ar" ? styles.containerLabelRTL : styles.containerLabelLTR} ref={handleRef ? el => handleRef(name, el) : null} onChange={handleOptionChange} value={selectedValue} >
      {isEmptyValue && <option value="">{t('Select...')}</option>}
      {options.map(option => (
        <option key={option.label} value={returnValue ? option.value : option.label} className={styles.customOption}>
          {name === 'time' ? option.label : t(option.label)}
        </option>
      ))}
    </select>
    <div className={styles.borderBottom}></div>
    <ErrorMessage message={errorMsg} isHidden={inputIsHidden} />
  </div>;
};

export default React.memo(SimpleDropDown);
