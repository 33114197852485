import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { HashRouter } from "react-router-dom";
import store from './store'
import ScrollToTop from './hooks/useScrollToTop';
import AppContainer from './components/Container/AppContainer';

function App() {
  return (
    <Provider store={store}>
      <HashRouter>
        <ScrollToTop />
        <AppContainer />
      </HashRouter>
    </Provider>
  );
}

export default App;
