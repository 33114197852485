import React, { useState, useEffect } from 'react';
import styles from './AdvancedDropDownSearch.module.css';
import { useTranslation } from 'react-i18next';
import TitleSection from '../TitleSection/TitleSection';
import ErrorMessage from './../../UI/ErrorMessage/ErrorMessage';
import Select from 'react-select';

const AdvancedDropDownSearch = ({ title, options, handleChange, name, preselectedValue, validationError = false }) => {
    const { t } = useTranslation();
    let formatedPreselectedValue = undefined;
    if (preselectedValue) {
        const option = options.find(option => option.value === preselectedValue);
        formatedPreselectedValue = `${option.value} - (${option.label})`;
    }
    const [selectedOption, setSelectedOption] = useState(preselectedValue);
    const [errorMsg, setErrorMsg] = useState('');
    const [inputIsHidden, setInputIsHidden] = useState(true);
    const placeholderText = t('Area code');

    useEffect(() => {
        if (validationError && !selectedOption) {
            setErrorMsg('Please select one of the options above');
            setInputIsHidden(false);
        } else {
            setErrorMsg('');
            setInputIsHidden(true);
        }
    }, [validationError, selectedOption]);

    const handleOptionChange = event => {
        const selectedValue = event.value;
        setSelectedOption(selectedValue);
        let result = options.find(option => option.value === selectedValue);
        handleChange && handleChange(name, result);
    };

    const formattedOptions = options.map(option => ({
        ...option,
        label: `${option.value} - (${option.label})`
    }));

    return <div className={styles.container}>
        {title.length > 0 && <TitleSection title={title} />}
        <Select
            defaultValue={selectedOption}
            onChange={handleOptionChange}
            options={formattedOptions}
            placeholder={formatedPreselectedValue ? formatedPreselectedValue : placeholderText}
            styles={{
                control: (base) => ({ ...base, height: '50px', border: inputIsHidden ? '1px solid #e3e4e7' : '1px solid #d92926', borderRadius: '0px', fontSize: '12px', fontFamily: 'HelveticaNeue', color: '#62698c' }),
                menu: (base) => ({ ...base, fontSize: '12px', fontFamily: 'HelveticaNeue', color: '#62698c' }),
                input: (base) => ({ ...base, color: '#62698c' }),
                singleValue: (base) => ({ ...base, color: '#62698c' }),
                placeholder: (base) => ({ ...base, color: '#62698c' })
            }}
        />
        <ErrorMessage message={errorMsg} isHidden={inputIsHidden} />
    </div>;
};

export default React.memo(AdvancedDropDownSearch);