import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AppointmentListContent.module.css';
import Loading from '../../Loading/Loading';
import AppointmentListTable from './../AppointmentListTable/AppointmentListTable';
import NoAppointments from '../NoAppointments/NoAppointments';
import AppointmentListTableMobile from './../AppointmentListTableMobile/AppointmentListTableMobile';

const AppointmentListContent = ({ isLoadingList, currentList, isNextAppointmentSelected, cancelAppoitmentClicked, clientLanguage }) => {
    const { t } = useTranslation();

    if (isLoadingList) {
        return <Loading />;
    }
    if (currentList?.length > 0) {
        return (
            <div className={styles.listContainer}>
                <table className={styles.tableContainer}>
                    <tbody>
                        <tr>
                            <th>{t("DATE")}</th>
                            <th>{t("TIME")}</th>
                            <th>{t("LOCATION")}</th>
                            <th>{t("SUBJECT")}</th>
                            <th>{isNextAppointmentSelected ? '' : t("ATTENDANCE")}</th>
                        </tr>
                        <AppointmentListTable appointmentList={currentList} isNextAppointmentSelected={isNextAppointmentSelected} cancelAppoitmentClicked={cancelAppoitmentClicked} clientLanguage={clientLanguage} />
                    </tbody>
                </table>
                <div className={styles.tableContainerMobile}>
                    <AppointmentListTableMobile appointmentList={currentList} isNextAppointmentSelected={isNextAppointmentSelected} cancelAppoitmentClicked={cancelAppoitmentClicked} clientLanguage={clientLanguage} />
                </div>
            </div>
        );
    }
    return <NoAppointments isNextAppointmentSelected={isNextAppointmentSelected} />;
};

export default React.memo(AppointmentListContent);
