import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './TitleSection.module.css';
import { replaceCharacter } from './../../utils/Utils';


const TitleSection = ({ title, subTitle }) => {
  const { t } = useTranslation();
  let fixedSubtitle = subTitle;
  if (subTitle) {
    fixedSubtitle = replaceCharacter('_', ' ', subTitle);
  }

  return <div className={styles.container}>
    <p className={styles.titleSectionLabel}>{t(title)} {subTitle && ` - ${fixedSubtitle}`}</p>
  </div>;
};

export default React.memo(TitleSection);