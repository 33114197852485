import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const useLanguageChangedListenerInit = (callback) => {
    const { i18n } = useTranslation();

    useEffect(() => {
        const languageChangedListener = (lang) => {
            callback(lang);
        };
        i18n.on('languageChanged', languageChangedListener);
        return () => {
            i18n.off('languageChanged', languageChangedListener);
        };
    }, [i18n, callback]);
};

export default useLanguageChangedListenerInit;
