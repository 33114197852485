import React from 'react';
import styles from './HeaderMobile.module.css';
import { useSelector } from 'react-redux';
import myProfileMobile from './../../../styles/Images/headerMobile/myProfileMobile.png';

const HeaderMobile = () => {

  const isAuth = useSelector((state) => state.config.isAuth);
  const { returnHomeUrl, returnProfileUrl } = useSelector((state) => state.config.clientInfos);
  const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
  const homeUrl = returnHomeUrl || 'https://www.chaumet.com';
  const profileUrl = returnProfileUrl || 'https://www.chaumet.com';

  return <div className={styles.headerContent}>
    <div className={clientLanguage === "ar" ? styles.logoContainerRTL : styles.logoContainerLTR}>
      <a href={homeUrl} ><img src="assets/logoMain.png" alt="Chaumet" className={clientLanguage === "ar" ? styles.logoRTL : styles.logoLTR}></img></a>
    </div>
    <div className={clientLanguage === "ar" ? styles.arrowBackRightSpaceRTL : styles.arrowBackRightSpaceLTR}>
      <a href={profileUrl} >
        {isAuth && <img src={myProfileMobile} alt="Chaumet" className={styles.myProfileIconMobile}></img>}
      </a>
    </div>
  </div>

}

export default HeaderMobile;