import { salesforce_instance } from './salesforce';

export const getClientInfos = async id => {
    return await salesforce_instance.get({ url: `/baaClientInfos?key=${id}` });
};

export const getAppointmentList = async id => {
    return await salesforce_instance.get({ url: `/baaCancelAppointments?clientId=${id}` });
};

export const getCountriesStore = async () => {
    return await salesforce_instance.get({ url: '/baaCreateAppointments' });
};

export const getTimeSlots = async id => {
    return await salesforce_instance.get({ url: `/baaCreateAppointments?serviceTerritorryId=${id}` });
};

export const checkTokenCapcha = async token => {
    return await salesforce_instance.get({ url: `/baaCreateAppointments?token=${token}` });
};

export const cancelAppointment = async (body) => {
    return await salesforce_instance.post(
        {
            url: `/baaCancelAppointments`,
            body: body
        }
    )
};

export const createAppointment = async (body) => {
    return await salesforce_instance.post(
        {
            url: `/baaCreateAppointments`,
            body: body
        }
    )
};