import React, { useState, useCallback } from 'react';
import styles from './CancelAppointment.module.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Footer from '../components/Footer/Footer';
import SessionExpired from '../components/SessionExpired/SessionExpired';
import HeaderLogged from '../components/Header/HeaderLogged';
import HeaderGuest from '../components/Header/HeaderGuest';
import HeaderMobile from '../components/Header/HeaderMobile/HeaderMobile';
import CancelButton from './../UI/CancelButton/CancelButton';
import AppointmentCreated from './../components/CreateAppointment/AppointmentCreated/AppointmentCreated';
import LoaderRequest from './../components/Loading/LoaderRequest/LoaderRequest';
import ModalCancelAppointmentDone from './../components/Modal/ModalCancelAppointmentDone/ModalCancelAppointmentDone';
import { cancelAppointment } from './../services/salesforce/index';

const CancelAppointment = () => {
    const { t } = useTranslation();
    const isAuth = useSelector((state) => state.config.isAuth);
    const isSessionExpired = useSelector((state) => state.config.isSessionExpired);
    const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
    const appointmentData = useSelector((state) => state.config.clientInfos);
    const [isLoadingPopUp, setIsLoadingPopUp] = useState(false);
    const [isLoadingPopUpDone, setIsLoadingPopUpDone] = useState(false);
    const [isAppointmentCancelled, setIsAppointmentCancelled] = useState(false);
    let selectedAppointment = {
        salutation: appointmentData && appointmentData.salutation,
        firstName: appointmentData && appointmentData.firstName,
        lastName: appointmentData && appointmentData.lastName,
        email: appointmentData && appointmentData.email,
        countryCode: appointmentData && appointmentData.countryCode,
        mobile: appointmentData && appointmentData.mobile,
        subject: appointmentData && appointmentData.subject,
        comment: appointmentData && appointmentData.comment,
        modality: appointmentData && appointmentData.modality,
        storeLabel: appointmentData && appointmentData.storeLabel,
        country: appointmentData && appointmentData.country,
        startTime: appointmentData && appointmentData.startTime,
        endTime: appointmentData && appointmentData.endTime,
        appointmentType: appointmentData && appointmentData.modality,
        selectedTimeZone: appointmentData && appointmentData.timeZone,
        landingPage: appointmentData && appointmentData.landingPage,
        timeZone: appointmentData && appointmentData.timeZone
    };

    const cancelAppointmentClicked = useCallback(async () => {
        setIsLoadingPopUp(true);
        const data = { uuid: appointmentData.uuid };
        const response = await cancelAppointment(data);
        if (response.success === true) {
            setIsLoadingPopUpDone(true);
            setIsAppointmentCancelled(true);
        }
        setIsLoadingPopUp(false);
    }, [appointmentData.uuid]);

    const handleCloseLoader = () => {
        setIsLoadingPopUp(false);
    };

    const handleCloseModalAppDone = () => {
        setIsLoadingPopUpDone(false);
    };

    return <>
        <div className={clientLanguage === "ar" ? styles.containerRTL : styles.containerLTR}>
            <HeaderMobile clientLanguage={clientLanguage} />{isAuth ? <HeaderLogged clientLanguage={clientLanguage} /> : <HeaderGuest clientLanguage={clientLanguage} />}
            {!isSessionExpired ? <div className={clientLanguage === "ar" ? styles.containerCancelRTL : styles.containerCancelLTR}>
                <ModalCancelAppointmentDone open={isLoadingPopUpDone} onClose={handleCloseModalAppDone} selectedAppointment={selectedAppointment} clientLanguage={clientLanguage} />
                <AppointmentCreated userData={selectedAppointment} />
                {!isAppointmentCancelled && <CancelButton title={t('CANCEL MY APPOINTMENT')} handleButtonClicked={cancelAppointmentClicked} />}
                <LoaderRequest open={isLoadingPopUp} onClose={handleCloseLoader} />
            </div>
                : <SessionExpired />}
        </div>
        <div>
            <Footer />
        </div>
    </>
};

export default CancelAppointment;