import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ThanksYou.module.css';
import thanksYou from '../../styles/Images/createAppointment/form/thanksYou.png';

const ThanksYou = ({ clientName, subtitle }) => {
    const { t } = useTranslation();

    return <div className={styles.container}>
        <div className={styles.containerThankYouImage}>
            <img src={thanksYou} alt="thanksYouIcon" className={styles.thanksYouIcon}></img>
        </div>
        <div className={styles.containerThankYouLabel}>
            <p>{t('Thank you')}{clientName ? ', ' : ''}<span className={styles.thankYouLabelName}>{clientName || ''}!</span></p>
        </div>
        {subtitle && <div className={styles.containerThankYouLabelSubtitle}>
            <p>{t(subtitle)}</p>
        </div>}
    </div>;
};

export default React.memo(ThanksYou);