import { configureStore } from '@reduxjs/toolkit';
import configReducer from './config';
import { initializeApp } from './action';
import { isProdEnvironment } from './../utils/Utils';

const isProduction = isProdEnvironment();

const store = configureStore({
  reducer: { config: configReducer },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  devTools: !isProduction, // Disable Redux DevTools in production
});

store.dispatch(initializeApp());

export default store;