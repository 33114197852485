import queryString from 'query-string';
import { v4 as uuidv4 } from 'uuid';
import { getDateFormat, setLocalDate } from './Utils'

const dateTimeFormat = 'YYYYMMDDTHHmmss';
const outlookDateTimeFormat = 'YYYY-MM-DDTHH:mm:ssZ';
const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;


const getDateTime = (startTime, endTime, dateFormat, lng) => {
    let startDateTimeFixed;
    let endDateTimeFixed;
    if (lng === 'ar') {
        setLocalDate('en');
        startDateTimeFixed = getDateFormat(startTime, dateFormat, timeZone);
        endDateTimeFixed = getDateFormat(endTime, dateFormat, timeZone);
        setLocalDate('ar');
    }
    else {
        startDateTimeFixed = getDateFormat(startTime, dateFormat, timeZone);
        endDateTimeFixed = getDateFormat(endTime, dateFormat, timeZone);
    }
    return { startDateTimeFixed, endDateTimeFixed };
};

export const calendarTypes = [
    { value: 'Apple', label: 'Apple' },
    { value: 'Google', label: 'Google' },
    { value: 'Outlook', label: 'Outlook' },
    { value: 'Yahoo', label: 'Yahoo' },
    { value: 'ics', label: 'ics' }
];

export const google = ({ title, description, location, startTime, endTime, lng }) => {
    const { startDateTimeFixed, endDateTimeFixed } = getDateTime(startTime, endTime, dateTimeFormat, lng);
    const summary = 'Chaumet - ' + title;
    const details = {
        action: "TEMPLATE",
        text: summary,
        details: description,
        location: location,
        trp: undefined,
        dates: `${startDateTimeFixed}/${endDateTimeFixed}`,
        recur: undefined
    };
    return `https://calendar.google.com/calendar/render?${queryString.stringify(details)}`;
};

export const outlook = calendarEvent => {
    const { title, description, location, startTime, endTime, lng } = calendarEvent;
    const { startDateTimeFixed, endDateTimeFixed } = getDateTime(startTime, endTime, outlookDateTimeFormat, lng);
    const summary = 'Chaumet - ' + title;
    const details = {
        path: "/calendar/action/compose",
        rru: "addevent",
        startdt: startDateTimeFixed,
        enddt: endDateTimeFixed,
        subject: summary,
        body: description,
        location: location,
        allday: false,
    };
    return `https://outlook.live.com/calendar/0/action/compose?${queryString.stringify(details)}`;
};

export const outlookMobile = ({ title, description, location, startTime, endTime, lng }) => {
    const { startDateTimeFixed, endDateTimeFixed } = getDateTime(startTime, endTime, outlookDateTimeFormat, lng);
    const summary = 'Chaumet - ' + title;
    const details = {
        path: "/calendar/action/compose",
        rru: "addevent",
        startdt: startDateTimeFixed,
        enddt: endDateTimeFixed,
        subject: summary,
        body: description,
        location: location,
        allday: false,
    };
    return `https://outlook.office.com/calendar/0/deeplink/compose?${queryString.stringify(details)}`;
};

export const yahoo = ({ title, description, location, startTime, endTime, lng }) => {
    const { startDateTimeFixed, endDateTimeFixed } = getDateTime(startTime, endTime, dateTimeFormat, lng);
    const summary = 'Chaumet - ' + title;
    const details = {
        v: 60,
        title: summary,
        st: startDateTimeFixed,
        et: endDateTimeFixed,
        desc: description,
        in_loc: location,
        dur: false,
    };
    return `https://calendar.yahoo.com/?${queryString.stringify(details)}`;
};

export const ics = (data, t) => {
    const { title, description, location, startTime, endTime, lng } = data;
    const { startDateTimeFixed, endDateTimeFixed } = getDateTime(startTime, endTime, dateTimeFormat, lng);
    const descriptionFormatted = description || '';
    const summary = 'Chaumet - ' + title;
    const calendarEventLink = `BEGIN:VCALENDAR\r\n` +
        `PRODID:-CHAUMET//APPOINTMENT\r\n` +
        `VERSION:2.0\r\n` +
        `BEGIN:VEVENT\r\n` +
        `UID:${uuidv4()}@default\r\n` +
        `CLASS:PUBLIC\r\n` +
        `SUMMARY:${summary}\r\n` +
        `DESCRIPTION:${descriptionFormatted}\r\n` +
        `LOCATION:${location}\r\n` +
        `DTSTART:${startDateTimeFixed}\r\n` +
        `DTEND:${endDateTimeFixed}\r\n` +
        `TRANSP:TRANSPARENT\r\n` +
        `END:VEVENT\r\n` +
        `END:VCALENDAR`;
    const blob = new Blob([calendarEventLink], { type: 'text/calendar;charset=utf-8' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const titleDoc = t('Votre Rendez-vous Chaumet');
    link.download = titleDoc + '.ics';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
