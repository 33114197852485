import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './SimpleCustomInput.module.css';
import TitleSection from './../TitleSection/TitleSection';
import ErrorMessage from './../../UI/ErrorMessage/ErrorMessage';
import { isEmailValid, isOnlyNumber, isEmpty, isValidString } from './../../utils/formUtils';


const SimpleCustomInput = ({ title, subTitle, name, returnValue = false, handleRef, preselectedValue, handleInput, isNumericOnly = false, isEmail = false, isHalfInput = false, validationError = false, maxLength = 254 }) => {

    const lng = useSelector((state) => state.config.lng || 'en_US');
    const [currentValue, setCurrentValue] = useState(preselectedValue ? preselectedValue : '');
    const [errorMsg, setErrorMsg] = useState('');
    const [inputIsHidden, setInputIsHidden] = useState(true);

    useEffect(() => {
        setInputIsHidden(!validationError);
        if (validationError) {
            setErrorMsg('Please fill this field');
        }
        else {
            setErrorMsg('');
        }
    }, [validationError]);

    const handleChange = event => {
        const value = event.target.value;
        const conditionalValue = returnValue ? { name, value } : value;
        handleInput && handleInput(conditionalValue);
        setCurrentValue(value);
        const isValueEmpty = isEmpty(value);
        if (isValueEmpty) {
            setErrorMsg('Please fill this field');
            setInputIsHidden(false);
        }
        else if (!isValidString(value)) {
            setErrorMsg('Input contains invalid characters');
            setInputIsHidden(false);
        }
        else if (isNumericOnly) {
            if (!isOnlyNumber(value)) {
                setErrorMsg('Please enter a valid phone format');
                setInputIsHidden(false);
            }
            else {
                setErrorMsg('');
                setInputIsHidden(true);
            }
        }
        else if (isEmail) {
            if (!isEmailValid(value)) {
                setErrorMsg('Please enter a valid email format');
                setInputIsHidden(false);
            }
            else {
                setErrorMsg('');
                setInputIsHidden(true);
            }
        }
        else {
            setErrorMsg('');
            setInputIsHidden(true);
        }
    };

    return <div className={styles.container}>
        <TitleSection title={title} subTitle={subTitle} />
        <input type={isEmail ? 'email' : 'text'} name={title} className={inputIsHidden ? lng === 'ar' ? styles.inputStyleRTL : styles.inputStyleLTR : lng === 'ar' ? styles.inputStyleErrorRTL : styles.inputStyleErrorLTR} ref={handleRef ? el => handleRef(name, el) : null} onChange={handleChange} value={currentValue} maxLength={maxLength} />
        <ErrorMessage message={errorMsg} isHidden={inputIsHidden} isHalfInput={isHalfInput} />
    </div>;
};

export default React.memo(SimpleCustomInput);