import React from 'react';
import AppointmentListTableMobileItem from './../AppointmentListTableMobileItem/AppointmentListTableMobileItem';

const AppointmentListTableMobile = ({ appointmentList, isNextAppointmentSelected, cancelAppoitmentClicked, clientLanguage }) => {

  return <>
    {appointmentList && appointmentList.length > 0 && appointmentList.map(appointment => <AppointmentListTableMobileItem key={appointment.uuid} appointment={appointment} isNextAppointmentSelected={isNextAppointmentSelected} cancelAppoitmentClicked={cancelAppoitmentClicked} clientLanguage={clientLanguage} />)}
  </>
};

export default React.memo(AppointmentListTableMobile);