import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './CustomRadioButton.module.css';
import TitleSection from './../TitleSection/TitleSection';
import ErrorMessage from './../../UI/ErrorMessage/ErrorMessage';

const CustomRadioButton = ({ options, title, handleRef, handleRadioOptionChange, returnValue = false, name, preselectedValue, isHorizontalOptions = false, optionIsEmpty = false }) => {
    const { t } = useTranslation();
    const lng = useSelector((state) => state.config.lng);
    const [selectedOption, setSelectedOption] = useState(preselectedValue ? preselectedValue : '');
    const [errorMsg, setErrorMsg] = useState('');
    const [inputIsHidden, setInputIsHidden] = useState(true);

    useEffect(() => {
        setSelectedOption(preselectedValue);
    }, [preselectedValue]);

    useEffect(() => {
        if (optionIsEmpty && !selectedOption) {
            setErrorMsg('Please select one of the options above');
            setInputIsHidden(false);
        } else {
            setErrorMsg('');
            setInputIsHidden(true);
        }
    }, [optionIsEmpty, selectedOption]);

    const handleOptionChange = selectedOption => {
        const value = returnValue ? { name, value: selectedOption } : selectedOption;
        handleRadioOptionChange && handleRadioOptionChange(value);
        setSelectedOption(selectedOption);
    };

    return <div className={lng === "ar" ? styles.containerRTL : styles.containerLTR}>
        {title && <TitleSection title={title} />}
        <div className={isHorizontalOptions ? lng === "ar" ? styles.containerRadioListHorizontalRTL : styles.containerRadioListHorizontalLTR : lng === "ar" ? styles.containerRadioListRTL : styles.containerRadioListLTR}>
            {options.map(option => (
                <div className={isHorizontalOptions ? lng === "ar" ? styles.containerRadioListItemHorizontalRTL : styles.containerRadioListItemHorizontalLTR : lng === "ar" ? styles.containerRadioListItemRTL : styles.containerRadioListItemLTR} key={option.value}>
                    <input
                        type="radio"
                        id={option.value}
                        value={option.value}
                        checked={selectedOption === option.value}
                        ref={handleRef ? el => handleRef(name, el) : null}
                        onChange={() => handleOptionChange(option.value)}
                    />
                    <label className={styles.labelOption} htmlFor={option.value}>{t(option.label)}</label>
                </div>
            ))}
        </div>
        <ErrorMessage message={errorMsg} isHidden={inputIsHidden} />
    </div>;
};

export default React.memo(CustomRadioButton);