import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AppointmentTabs.module.css';

const AppointmentTabs = ({ clientLanguage, isNextAppointmentSelected, setIsNextAppointmentSelected }) => {
    const { t } = useTranslation();
    const nextAppointmentContainerRef = useRef(null);
    const pastAppointmentContainerRef = useRef(null);

    const updateSelectedTab = value => {
        setIsNextAppointmentSelected(value);
        const containerElement = value ? nextAppointmentContainerRef.current : pastAppointmentContainerRef.current;
        containerElement.scrollIntoView({ behavior: "smooth", block: 'center', inline: 'center' });
    };

    return <div className={styles.container}>
        <div className={clientLanguage === "ar" ? styles.tabContainerRTL : styles.tabContainerLTR}>
            <div id="nextAppointmentContainer" ref={nextAppointmentContainerRef} onClick={() => updateSelectedTab(true)} className={isNextAppointmentSelected ? (clientLanguage === "ar" ? styles.nextAppointmentContainerSelectedRTL : styles.nextAppointmentContainerSelectedLTR) : (clientLanguage === "ar" ? styles.nextAppointmentContainerRTL : styles.nextAppointmentContainerLTR)}>
                <p className={isNextAppointmentSelected ? styles.nextAppointmentLabelSelected : styles.nextAppointmentLabel}>{t("MY NEXT APPOINTMENTS")}</p>
            </div>
            <div id="pastAppointmentContainer" ref={pastAppointmentContainerRef} onClick={() => updateSelectedTab(false)} className={!isNextAppointmentSelected ? (clientLanguage === "ar" ? styles.pastAppointmentContainerSelectedRTL : styles.pastAppointmentContainerSelectedLTR) : (clientLanguage === "ar" ? styles.pastAppointmentContainerRTL : styles.pastAppointmentContainerLTR)}>
                <p className={!isNextAppointmentSelected ? styles.pastAppointmentLabelSelected : styles.pastAppointmentLabel}>{t("MY PAST APPOINTMENTS")}</p>
            </div>
        </div>
        <div className={clientLanguage === "ar" ? styles.tabContainerMobileRTL : styles.tabContainerMobileLTR}>
            <div id="nextAppointmentContainer" ref={nextAppointmentContainerRef} onClick={() => updateSelectedTab(true)} className={isNextAppointmentSelected ? (clientLanguage === "ar" ? styles.nextAppointmentContainerSelectedRTL : styles.nextAppointmentContainerSelectedLTR) : (clientLanguage === "ar" ? styles.nextAppointmentContainerRTL : styles.nextAppointmentContainerLTR)}>
                <p className={isNextAppointmentSelected ? styles.nextAppointmentLabelSelected : styles.nextAppointmentLabel}>{t("MY NEXT APPOINTMENTS")}</p>
            </div>
            <div id="pastAppointmentContainer" ref={pastAppointmentContainerRef} onClick={() => updateSelectedTab(false)} className={!isNextAppointmentSelected ? (clientLanguage === "ar" ? styles.pastAppointmentContainerSelectedRTL : styles.pastAppointmentContainerSelectedLTR) : (clientLanguage === "ar" ? styles.pastAppointmentContainerRTL : styles.pastAppointmentContainerLTR)}>
                <p className={!isNextAppointmentSelected ? styles.pastAppointmentLabelSelected : styles.pastAppointmentLabel}>{t("MY PAST APPOINTMENTS")}</p>
            </div>
        </div>
    </div>;
};

export default React.memo(AppointmentTabs);