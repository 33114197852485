import React from 'react';
import styles from './InfosMessage.module.css';
import { useTranslation } from 'react-i18next';

const InfosMessage = ({ isDisplay, clientLanguage }) => {
    const { t } = useTranslation();

    const bodyPart1 = 'We kindly request that you arrive at your reservation time, reservations may be cancelled after a 15-minute delay without prior notice';
    const bodyPart2 = 'We kindly ask that each individual refrains from making multiple reservations to ensure a comfortable experience for all our guests';

    return <>
        {isDisplay && <>
            <div className={clientLanguage === "ar" ? styles.containerRTL : styles.containerLTR}>
                <div className={styles.bodyContainer}>
                    <p className={styles.bodyContainerLabel}>{t(bodyPart1)}.</p>
                    <p className={styles.bodyContainerLabel}>{t(bodyPart2)}.</p>
                </div>
            </div>
        </>
        }
    </>
};

export default React.memo(InfosMessage);