import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './AddToCalendar.module.css';
import DropdownLink from './../../UI/DropdownLink/DropdownLink';
import { calendarTypes, google, outlook, outlookMobile, yahoo, ics } from './../../utils/AddCalendarUtils';
import { isMobile } from './../../utils/Utils';
import appleCalendar from './../../styles/Images/addCalendar/appleCalendar.png';
import outlookCalendar from './../../styles/Images/addCalendar/outlookCalendar.png';
import yahooCalendar from './../../styles/Images/addCalendar/yahooCalendar.png';
import googleCalendar from './../../styles/Images/addCalendar/googleCalendar.png';
import icsFile from './../../styles/Images/addCalendar/icsFile.png';

const AddToCalendar = ({ data }) => {
    const { t } = useTranslation();
    const [calendarsList, setCalendarsList] = useState([]);

    useEffect(() => {
        initCalendars();
    }, []);

    const initCalendars = () => {
        const calendarArray = [];
        calendarTypes.forEach(calendarType => {
            const { link, isLink, img } = getCalendarTypeLink(calendarType.label);
            let label = calendarType.label;
            if (calendarType.label === 'ics') {
                label = t('Appointment file')
            }
            calendarArray.push({ label: label, link: link, isLink: isLink, img: img });
        });
        setCalendarsList(calendarArray);
    };

    const getCalendarTypeLink = calendarType => {
        switch (calendarType) {
            case 'Apple':
                return { link: undefined, isLink: false, img: appleCalendar };
            case 'Google':
                return { link: google(data), isLink: true, img: googleCalendar };
            case 'Outlook':
                return isMobile() ? { link: outlookMobile(data), isLink: true, img: outlookCalendar } : { link: outlook(data), isLink: true, img: outlookCalendar };
            case 'Yahoo':
                return { link: yahoo(data), isLink: true, img: yahooCalendar };
            case 'ics':
                return { link: undefined, isLink: false, img: icsFile };
            default:
                console.error('Unsupported calendar type');
        }
    };

    const handleClick = () => {
        ics(data, t);
    };

    return <div className={styles.container}>
        <DropdownLink name="calendar" options={calendarsList} title={"ADD TO MY CALENDAR"} isEmptyValue={true} handleClick={handleClick} />
    </div>;
};

export default React.memo(AddToCalendar);