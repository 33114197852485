import React, { forwardRef, useState, useCallback, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import styles from './CreateClientForm.module.css';
import { useTranslation } from 'react-i18next';
import CustomRadioButton from '../../UI/CustomRadioButton/CustomRadioButton';
import SimpleCustomInput from './../../UI/SimpleCustomInput/SimpleCustomInput';
import AdvancedDropDownSearch from './../../UI/AdvancedDropDownSearch/AdvancedDropDownSearch';
import { isEmpty, isEmailValid, isOnlyNumber, isValidString } from '../../utils/formUtils';
import { clientTitleOptions } from '../../services/data';

const CreateClientForm = forwardRef(({ clientLanguage, isFormSubmitted, updateFormValues }, ref) => {
    const { t } = useTranslation();
    const appointmentParams = useSelector((state) => state.config.appointmentParams);
    const contactDetailsParams = useSelector((state) => state.config.contactDetailsParams);
    const countriesCodesPicklist = useSelector((state) => state.config.clientInfos.countriesCodesPicklist.options);

    const [formValues, setFormValues] = useState({
        clientTitle: contactDetailsParams?.clientTitle || undefined,
        selectedCountryCode: contactDetailsParams?.selectedCountryCode || undefined,
        selectedCountryLabel: contactDetailsParams?.selectedCountryLabel || undefined,
        firstName: contactDetailsParams?.firstName || '',
        lastName: contactDetailsParams?.lastName || '',
        email: contactDetailsParams?.email || '',
        phone: contactDetailsParams?.phone || ''
    });

    const [validationState, setValidationState] = useState({
        clientTitleError: false,
        firstNameError: false,
        lastNameError: false,
        emailError: false,
        phoneError: false,
        countryCodeError: false
    });

    const handleChange = useCallback(({ name, value }) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            [name]: value,
        }));
        updateFormValues(name, value);
    }, [updateFormValues]);

    const handleAreaCodeChange = useCallback((name, value) => {
        setFormValues((prevFormValues) => ({
            ...prevFormValues,
            selectedCountryCode: value.label,
            selectedCountryLabel: value.value,
        }));
        updateFormValues('selectedCountryCode', value.label);
        updateFormValues('selectedCountryLabel', value.value);
    }, [updateFormValues]);

    const createFormData = useCallback(() => {
        const values = {
            ...appointmentParams,
            salutation: formValues.clientTitle,
            firstName: formValues.firstName,
            lastName: formValues.lastName,
            email: formValues.email,
            mobile: formValues.phone,
            countryCode: formValues.selectedCountryCode,
            mobile1CountryCode: formValues.selectedCountryLabel,
        };
        return values;
    }, [formValues, appointmentParams]);

    const validateForm = useCallback(() => {
        const formValues = createFormData();
        const newValidationState = {
            clientTitleError: isEmpty(formValues.salutation) || !isValidString(formValues.salutation),
            firstNameError: isEmpty(formValues.firstName) || !isValidString(formValues.firstName),
            lastNameError: isEmpty(formValues.lastName) || !isValidString(formValues.lastName),
            emailError: isEmpty(formValues.email) || !isEmailValid(formValues.email),
            phoneError: isEmpty(formValues.mobile) || !isOnlyNumber(formValues.mobile),
            countryCodeError: isEmpty(formValues.countryCode) || !isValidString(formValues.countryCode)
        };
        setValidationState(newValidationState);
        const formIsValid = !Object.values(newValidationState).some((error) => error);
        return { formValuesClient: formValues, isFormIsValidClient: formIsValid };
    }, [createFormData]);

    useImperativeHandle(ref, () => ({
        validateForm: () => validateForm(),
    }));

    return <div className={styles.container}>
        <div className={styles.containerForm}>
            <div className={styles.titleLabelSectionContainer}>
                <p className={styles.titleLabelSectionOptional}>2. {t("CONTACT DETAILS")}</p>
            </div>
            <> <CustomRadioButton name="clientTitle" options={clientTitleOptions} title={t("CIVILITY")} preselectedValue={formValues.clientTitle} handleRadioOptionChange={handleChange} optionIsEmpty={isFormSubmitted && validationState.clientTitleError} returnValue={true} />
                <div className={styles.lineSeparator}></div>
                <div className={clientLanguage === "ar" ? styles.dropDownContainerRTL : styles.dropDownContainerLTR}>
                    <SimpleCustomInput name="firstName" title={t("FIRST NAME")} handleInput={handleChange} preselectedValue={formValues.firstName} validationError={isFormSubmitted && validationState.firstNameError} maxLength={40} returnValue={true} />
                    <SimpleCustomInput name="lastName" title={t("LAST NAME")} handleInput={handleChange} preselectedValue={formValues.lastName} validationError={isFormSubmitted && validationState.lastNameError} maxLength={40} returnValue={true} />
                </div>
                <div className={clientLanguage === "ar" ? styles.customSectionRTL : styles.customSectionLTR}>
                    <div className={styles.section1}>
                        <SimpleCustomInput name="email" title={t("EMAIL")} isEmail={true} handleInput={handleChange} preselectedValue={formValues.email} validationError={isFormSubmitted && validationState.emailError} maxLength={60} returnValue={true} />
                    </div>
                    <div className={clientLanguage === "ar" ? styles.section2RTL : styles.section2LTR}>
                        <div className={clientLanguage === "ar" ? styles.subSection1RTL : styles.subSection1LTR}>
                            <AdvancedDropDownSearch name="codeCountry" options={countriesCodesPicklist} title={t("PHONE")} handleChange={handleAreaCodeChange} preselectedValue={formValues.selectedCountryLabel} validationError={isFormSubmitted && validationState.countryCodeError} />
                        </div>
                        <div className={styles.subSection2}>
                            <SimpleCustomInput name="phone" title={t("")} isNumericOnly={true} isHalfInput={true} preselectedValue={formValues.phone} validationError={isFormSubmitted && validationState.phoneError} handleInput={handleChange} maxLength={15} returnValue={true} />
                        </div>
                    </div>
                </div>
            </>
        </div>
    </div >;
});

export default React.memo(CreateClientForm);