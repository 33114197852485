import React from 'react';
import { useSelector } from 'react-redux';
import Loading from './../components/Loading/Loading';
import Footer from '../components/Footer/Footer';
import SessionExpired from '../components/SessionExpired/SessionExpired';
import styles from './Home.module.css';
import HeaderLogged from '../components/Header/HeaderLogged';
import HeaderGuest from '../components/Header/HeaderGuest';
import HeaderMobile from '../components/Header/HeaderMobile/HeaderMobile';
import CreateAppointment from '../components/CreateAppointment/CreateAppointment';

const Home = () => {

  const { isAuth, isSessionExpired, isAppInitialized } = useSelector((state) => state.config);
  const clientLanguage = useSelector((state) => state.config.lng || 'en_US');

  return <>
    <div className={clientLanguage === "ar" ? styles.containerRTL : styles.containerLTR}>
      <HeaderMobile />{isAuth ? <HeaderLogged /> : <HeaderGuest />}
      {!isAppInitialized ? <Loading /> : !isSessionExpired ?
        <CreateAppointment />
        : <SessionExpired />}
    </div>
    <div>
      <Footer />
    </div>
  </>
    ;
}

export default Home;


