import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ConfirmButton.module.css';

const ConfirmButton = ({ title, handleButtonClicked, buttonSize, isEnable = true }) => {
  const { t } = useTranslation();

  const containerClassName = `${styles.container} ${isEnable ? styles.container : styles.containerDisable} ${styles[buttonSize] || styles['large']}`;

  const handleConfirmButtonPressed = () => {
    if (isEnable) {
      handleButtonClicked();
    }
  };

  return <div className={containerClassName} onClick={handleConfirmButtonPressed}>
    <p className={styles.titleLabel}>{t(title)}</p>
  </div>;
};

export default React.memo(ConfirmButton);