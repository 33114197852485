import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import XHR from "i18next-xhr-backend";
import { getTranslationUrl } from "../utils/Utils";
import store from './../store/index';

i18n
  .use(initReactI18next)
  .use(XHR)
  .init({
    // lng: "en_US",
    // fallbackLng: "en_US",
    interpolation: { escapeValue: false },
    htmlTag: document.documentElement,
    defaultNS: ["common"],
    fallbackNS: ["common"],
    ns: ["common"],
    detection: {
      htmlTag: document.documentElement,
    },
    backend: {
      loadPath: () => {
        const lng = store.getState().config.lng;
        return `${getTranslationUrl()}/services/apexrest/ddc/translationDdc?app=Book An Appointment&format=json&lng=${lng}`;
      }
    },
    react: {
      wait: true,
      useSuspense: false,
    },
  });

export default i18n;
