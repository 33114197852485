import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CancelHeader.module.css';
import xCircle from '../../../styles/Images/x-circle.png';

const CancelHeader = () => {
    const { t } = useTranslation();

    return <div className={styles.container}>
        <div className={styles.containerCancelHeaderImage}>
            <img src={xCircle} alt="xCircleIcon" className={styles.cancelHeaderIcon}></img>
        </div>
        <div className={styles.containerCancelHeaderLabel}>
            <p>{t('Do you want to cancel your appointment?')}</p>
        </div>
    </div>;
};

export default React.memo(CancelHeader);