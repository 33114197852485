import React from 'react';
import styles from './CreateAppointment.module.css';
import AppointmentForm from './../AppointmentForm/AppointmentForm';

const CreateAppointment = () => {
  return <div className={styles.container}>
    <AppointmentForm />
  </div>
};

export default React.memo(CreateAppointment);