import axios from 'axios';
import { getEnvUrl } from './../../utils/Utils';
import { getAccessToken } from '../../utils/auth';

class Salesforce {
  constructor() {
    let access_token;
    let domain_url;
    domain_url = getEnvUrl();
    if (window && window.location && window.location.hostname === "localhost") {
      access_token = getAccessToken();
    } else {
      // eslint-disable-next-line no-undef
      access_token = sessionId;
    }

    let headers = {
      "Access-Control-Allow-Origin": "*",
      authorization: `Bearer ${access_token}`,
    };

    if (access_token === 'NULL_SESSION_ID') {
      headers = {
        "Access-Control-Allow-Origin": "*"
      };
    }

    this.instance = axios.create({
      baseURL:
        `${domain_url}/services/apexrest/baa`,
      headers: headers,
    });
  }

  get = async ({ url }) => {
    try {
      const { data } = await this.instance.get(`${url}`);
      return {
        success: true,
        data,
      };
    } catch (error) {
      if (error?.response?.data)
        console.dir(
          `ERROR GET : ${url}/ DATA : ${JSON.stringify(
            error?.response?.data
          )} / HEADER ${JSON.stringify(this.instance.defaults.headers.common)}`
        );
      else console.dir(error);
      return {
        success: false,
        errorMessage: "error-message",
      };
    }
  };

  post = async ({ url, body }) => {
    try {
      const { data } = await this.instance.post(`${url}`, body);
      return {
        success: true,
        data,
      };
    } catch (error) {
      if (error?.response?.data)
        console.dir(
          `ERROR POST : ${url}/ DATA : ${JSON.stringify(error?.response?.data)}`
        );
      else console.dir(error);
      return {
        success: false,
        errorMessage: "error-message",
      };
    }
  };

  put = async ({ url, body }) => {
    try {
      const { data } = await this.instance.put(`${url}`, body);
      return {
        success: true,
        data,
      };
    } catch (error) {
      if (error?.response?.data)
        console.dir(
          `ERROR PUT : ${url}/ DATA : ${JSON.stringify(error?.response?.data)}`
        );
      else console.dir(error);
      return {
        success: false,
        errorMessage: "error-message",
      };
    }
  };

}

export const salesforce_instance = new Salesforce();