import React, { useEffect } from 'react';
import styles from './AddToMyCalendar.module.css';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Footer from '../components/Footer/Footer';
import SessionExpired from '../components/SessionExpired/SessionExpired';
import HeaderLogged from '../components/Header/HeaderLogged';
import HeaderGuest from '../components/Header/HeaderGuest';
import HeaderMobile from '../components/Header/HeaderMobile/HeaderMobile';
import AddToCalendar from '../UI/AddToCalendar/AddToCalendar';
import { getLabelFromValue } from '../services/data';
import AppointmentCreated from '../components/CreateAppointment/AppointmentCreated/AppointmentCreated';

const AddToMyCalendar = () => {
    const { t } = useTranslation();
    const isAuth = useSelector((state) => state.config.isAuth);
    const isSessionExpired = useSelector((state) => state.config.isSessionExpired);
    const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
    const appointmentData = useSelector((state) => state.config.clientInfos);
    const { subject, modality, comment, endTime, startTime, timeZone, storeAdress } = appointmentData;
    const locationFull = storeAdress.street + ' ' + storeAdress.postalCode + ' ' + storeAdress.city + ' ' + storeAdress.country;
    const modalityLabel = getLabelFromValue(modality);
    const data = { title: t(subject) + ' - ' + t(modalityLabel), description: comment, location: locationFull, startTime: startTime, endTime: endTime, timeZone: timeZone, lng: clientLanguage };

    let selectedAppointment = {
        salutation: appointmentData && appointmentData.salutation,
        firstName: appointmentData && appointmentData.firstName,
        lastName: appointmentData && appointmentData.lastName,
        email: appointmentData && appointmentData.email,
        countryCode: appointmentData && appointmentData.countryCode,
        mobile: appointmentData && appointmentData.mobile,
        subject: appointmentData && appointmentData.subject,
        comment: appointmentData && appointmentData.comment,
        modality: appointmentData && appointmentData.modality,
        storeLabel: appointmentData && appointmentData.storeLabel,
        country: appointmentData && appointmentData.country,
        startTime: appointmentData && appointmentData.startTime,
        endTime: appointmentData && appointmentData.endTime,
        appointmentType: appointmentData && appointmentData.modality,
        selectedTimeZone: appointmentData && appointmentData.timeZone,
        landingPage: appointmentData && appointmentData.landingPage,
        timeZone: appointmentData && appointmentData.timeZone
    };

    return <>
        <div className={clientLanguage === "ar" ? styles.containerRTL : styles.containerLTR}>
            <HeaderMobile clientLanguage={clientLanguage} />{isAuth ? <HeaderLogged clientLanguage={clientLanguage} /> : <HeaderGuest clientLanguage={clientLanguage} />}
            {!isSessionExpired ? <div className={clientLanguage === "ar" ? styles.containerResumeRTL : styles.containerResumeLTR}>
                <AppointmentCreated userData={selectedAppointment} isCalendar={true} />
                <AddToCalendar data={data} />
            </div>
                : <SessionExpired />}
        </div>
        <div>
            <Footer />
        </div>
    </>
};

export default AddToMyCalendar;