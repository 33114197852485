import React from 'react';
import styles from './AppointmentListTableMobileItem.module.css';
import { useTranslation } from 'react-i18next';
import crossIcon from './../../../styles/Images/appointementListDesktop/cross.png';
import { getDateFormat, getFomatDate, getFomatTime, replaceCharacter } from '../../../utils/Utils';

const AppointmentListTableMobileItem = ({ appointment, isNextAppointmentSelected, cancelAppoitmentClicked, clientLanguage }) => {
    const { t } = useTranslation();
    const schedStartDate = new Date(appointment.startDateTime);
    const format = getFomatDate(clientLanguage);
    const formatTime = getFomatTime(clientLanguage);

    const timezone = replaceCharacter('_', ' ', appointment.timeZone);

    return <div className={styles.container}>
        {isNextAppointmentSelected ? <div>
            <div className={clientLanguage === "ar" ? styles.cancelAppointmentContainerRTL : styles.cancelAppointmentContainerLTR}>
                <div>
                    <img src={crossIcon} alt="delete" className={clientLanguage === "ar" ? styles.crossIconRTL : styles.crossIconLTR} onClick={() => cancelAppoitmentClicked(appointment)}></img>
                </div>
                <div>
                    <p className={styles.crossIconLabel} onClick={() => cancelAppoitmentClicked(appointment)}>{t("CANCEL")}</p>
                </div>
            </div>
            <div className={clientLanguage === "ar" ? styles.allInfosContainerRTL : styles.allInfosContainerLTR}>
                <div className={styles.additionalInformation}>{t(appointment.appointmentReason)}</div>
                <div className={styles.appointmentDateLabel}>{getDateFormat(schedStartDate, format, appointment.timeZone)} - {getDateFormat(schedStartDate, formatTime, appointment.timeZone)}  ({timezone})</div>
                <div className={styles.locationLabel}>{appointment.appointmentType === 'PEOPLE IN-PERSON' ? appointment.location + ' - ' + t('IN PERSON') : appointment.storeName + ' - ' + t(appointment.location)}</div>
            </div>
        </div>
            :
            <div className={clientLanguage === "ar" ? styles.allInfosContainerPastRTL : styles.allInfosContainerPastLTR}>
                <div className={styles.additionalInformationPast}>{t(appointment.appointmentReason)}</div>
                <div className={styles.appointmentDateLabelPast}>{getDateFormat(schedStartDate, format, appointment.timeZone)} - {getDateFormat(schedStartDate, formatTime, appointment.timeZone)}  ({timezone})</div>
                <div className={styles.locationLabel}>{appointment.appointmentType === 'PEOPLE IN-PERSON' ? appointment.location + ' - IN PERSON' : appointment.storeName + ' - ' + appointment.location}</div>
                <div className={appointment.status === 'Completed' ? styles.showLabel : styles.showLabelRed}>{appointment.status === 'Completed' ? t("SHOW") : t("NO SHOW")}</div>
            </div>}
    </div>
};

export default React.memo(AppointmentListTableMobileItem);