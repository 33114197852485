import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { configActions } from '../../store/config';
import styles from './AppointmentForm.module.css';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { getCountriesStore, getTimeSlots, createAppointment } from '../../services/salesforce/index';
import CustomRadioButton from '../../UI/CustomRadioButton/CustomRadioButton';
import SimpleDropDown from '../../UI/SimpleDropdown/SimpleDropDown';
import CalendarDropDown from '../../UI/CalendarDropDown/CalendarDropDown';
import ConfirmButton from '../../UI/ConfirmButton/ConfirmButton';
import CancelButton from '../../UI/CancelButton/CancelButton';
import SimpleTextArea from '../../UI/SimpleTextArea/SimpleTextArea';
import { radiobuttonOptions, countryCodeIndex } from '../../services/data';
import Loading from './../../components/Loading/Loading';
import { getFomatTime, getLastItemOfObject, getDateFormatNoTZUTC, isSamedates, setLocalDate, getDateFromDateTime } from '../../utils/Utils';
import LoadingOverlay from '../../UI/LoadingOverlay/LoadingOverlay';
import { isEmpty } from '../../utils/formUtils';
import { setEvent } from './../../utils/firebaseUtils';
import ErrorMessage from '../../UI/ErrorMessage/ErrorMessage';
import ModalLeaveWithoutSaving from './../Modal/ModalLeaveWithoutSaving/ModalLeaveWithoutSaving';
import ModalWebsiteInMaintenance from './../Modal/ModalWebsiteInMaintenance/ModalWebsiteInMaintenance';
import InfosMessage from './../../UI/InfosMessage/InfosMessage';

const AppointmentForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const clientInfos = useSelector((state) => state.config.clientInfos);
  const { clientId, storeCode, clientCountryCode, subjects } = clientInfos;
  const lng = useSelector((state) => state.config.lng);
  const appointmentParams = useSelector((state) => state.config.appointmentParams);
  const isAuth = useSelector((state) => state.config.isAuth);
  const formatTime = getFomatTime(lng);
  const [subject, setSubject] = useState(appointmentParams && appointmentParams.subject);
  const [modality, setModality] = useState(appointmentParams && appointmentParams.modality ? appointmentParams.modality : 'people');
  const [countriesList, setCountriesList] = useState([]);
  const [storesList, setStoresList] = useState([]);
  const [timeSlots, setTimeSlots] = useState({});
  const [selectedTimeSlot, setSelectedTimeSlot] = useState({});
  const [currentTimeSlots, setCurrentTimeSlots] = useState([]);
  const [selectedDateFormat, setSelectedDateFormat] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedStore, setSelectedStore] = useState('');
  const [selectedTimeZone, setSelectedTimeZone] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const inputRefs = useRef([]);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [validationState, setValidationState] = useState({
    countryError: false,
    commentError: false,
    startTimeError: false
  });
  const errorStatuses = ['errorTimeSlotAlreadyTake', 'errorFieldMissing', 'errorStoreNotFound', 'errorTimeSlotUnavailable'];
  const [errorMsg, setErrorMsg] = useState('There was an unexpected error with saving your appointment.');
  const [errorMsgMaintenance, setErrorMsgMaintenance] = useState({ title: '', body: '' });
  const [inputIsHidden, setInputIsHidden] = useState(true);
  const [isShowModalLate, setIsShowModalLate] = useState(false);
  const [isShowModalLeave, setIsShowModalLeave] = useState(false);
  const [isShowModalMaintenance, setIsShowModalMaintenance] = useState(false);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const isUpdate = clientInfos.landingPage === 'update';
  const [isConfirmButtonEnable, setIsConfirmButtonEnable] = useState(isUpdate ? false : true);
  const [selectedTerritoryId, setSelectedTerritoryId] = useState();
  const [selectedTime, setSelectedTime] = useState(appointmentParams.startTime);

  const matchingStore = storesList.find(store => store.value === selectedStore);
  const isSelectedStore = matchingStore?.storeCode === storeCode;

  let subjectOptions = subjects && subjects.map(subject => ({
    ...subject,
    value: subject.label
  })).sort((a, b) => a.position - b.position);

  useEffect(() => {
    getCountriesAndStores();
  }, []);

  useEffect(() => {
    filterTimeSlots(selectedDateFormat);
  }, [timeSlots, selectedDateFormat, lng]);

  useEffect(() => {
    checkIfShowLateModale(selectedCountryCode);
  }, [lng, selectedCountry, selectedCountryCode]);

  useEffect(() => {
    if (timeSlots) {
      updateTimeSlot();
    }
  }, [storesList, selectedStore]);

  const resetSelectedTime = () => {
    if (isUpdate && currentTimeSlots && currentTimeSlots.length > 0) {
      setSelectedTime();
    }
  };

  const updateTimeSlot = async () => {
    if (!isUpdate || !selectedTerritoryId) return;
    setIsRefreshing(true);
    const responseTimeSlots = await getTimeSlots(selectedTerritoryId);
    const timeSlotsCopy = processTimeSlotsResponse(responseTimeSlots);
    if (timeSlotsCopy) {
      if (isSelectedStore && appointmentParams?.startTime) {
        handleTimeSlotUpdate(timeSlotsCopy, appointmentParams);
      } else {
        setTimeSlots(timeSlotsCopy);
      }
      updateSelectedDateFormat(responseTimeSlots.data[0], appointmentParams.startTime);
    }
    setIsRefreshing(false);
  };

  const fetchTimeSlots = async (id) => {
    setIsRefreshing(true);
    if (!isUpdate) {
      const responseTimeSlots = await getTimeSlots(id);
      const timeSlotsCopy = processTimeSlotsResponse(responseTimeSlots);
      if (responseTimeSlots && responseTimeSlots.data && responseTimeSlots.data[0]) {
        if (timeSlotsCopy && appointmentParams?.startTime) {
          setSelectedTimeSlot(appointmentParams.startTime);
        }
        updateSelectedDateFormat(responseTimeSlots.data[0], appointmentParams?.startTime);
      }
    }
    setIsRefreshing(false);
  };

  const processTimeSlotsResponse = (responseTimeSlots) => {
    if (responseTimeSlots.success === true && responseTimeSlots.data.length > 0) {
      const timeSlotsCopy = structuredClone(responseTimeSlots.data[0]);
      setTimeSlots(timeSlotsCopy);
      return timeSlotsCopy;
    }
    return null;
  };

  const updateSelectedDateFormat = (responseTimeSlotsData, appointmentStartTime) => {
    let currentDate;
    if (lng === "ar") {
      setLocalDate('en');
      currentDate = moment(appointmentStartTime).format('YYYY-MM-DD');
      setLocalDate('ar');
    } else {
      currentDate = moment(appointmentStartTime).format('YYYY-MM-DD');
    }

    let lastKey;
    if (isSelectedStore) {
      lastKey = appointmentStartTime
        ? currentDate
        : getLastItemOfObject(responseTimeSlotsData);
    }
    else if (!isSelectedStore) {
      lastKey = getLastItemOfObject(responseTimeSlotsData);
      setSelectedTimeSlot();
    }

    setSelectedDateFormat(lastKey);
    if (timeSlots && Object.keys(timeSlots).length > 0) {
      filterTimeSlots(lastKey);
    }
  };

  const handleTimeSlotUpdate = (timeSlotsCopy, appointmentParams) => {
    if (appointmentParams?.startTime) {
      const formattedDate = getDateFromDateTime(appointmentParams.startTime);
      const currentDateTimeToInsert = {
        duration: appointmentParams.duration,
        endTime: appointmentParams.endTime,
        remainingAppointments: "1",
        startTime: appointmentParams.startTime,
        territoryId: appointmentParams.territoryId,
      };

      if (timeSlotsCopy[formattedDate]) {
        const existingSlot = timeSlotsCopy[formattedDate].find(
          (slot) => slot.startTime === appointmentParams.startTime
        );
        if (!existingSlot) {
          timeSlotsCopy[formattedDate].push(currentDateTimeToInsert);
        }
      } else {
        timeSlotsCopy[formattedDate] = [currentDateTimeToInsert];
      }

      timeSlotsCopy[formattedDate].sort(
        (a, b) => new Date(a.startTime) - new Date(b.startTime)
      );

      setTimeSlots(timeSlotsCopy);
      setSelectedTimeSlot(appointmentParams.startTime);
    } else {
      setTimeSlots({});
      setSelectedTimeSlot();
    }
  };

  const filterTimeSlots = value => {
    setSelectedDateFormat(value);
    const matchingArray = timeSlots[value] || [];
    const timesOptions = matchingArray.map(({ startTime }) => ({
      value: startTime,
      label: getDateFormatNoTZUTC(startTime, formatTime)
    }));
    setCurrentTimeSlots(timesOptions);

    if (appointmentParams && appointmentParams.startTime && inputRefs.current.time) {
    }
    else if (timesOptions[0]) {
      inputRefs.current.time.value = timesOptions[0].value;
    }
  };

  const setStoresListData = (value, list = countriesList, currentStore) => {
    const selectedOptions = list.find(option => option.label === value);
    if (selectedOptions) {
      const updatedSelectedObjectsArray = selectedOptions.value.map(obj => ({ label: obj.Name, value: obj.Id, TimeZone: obj.TimeZone, storeCode: obj.StoreCode }));
      setStoresList(updatedSelectedObjectsArray);
      const updatedSelectedObjectsArrayCopy = [...updatedSelectedObjectsArray];
      const matchingObject = updatedSelectedObjectsArrayCopy.find(obj => obj.value === appointmentParams.store);
      let territoryId;
      let currentTimeZone;
      if (matchingObject && appointmentParams && appointmentParams.store) {
        territoryId = matchingObject.value;
        currentTimeZone = matchingObject.TimeZone;
      }
      else {
        territoryId = updatedSelectedObjectsArray[0].value;
        currentTimeZone = updatedSelectedObjectsArray[0].TimeZone;
      }
      if (territoryId) {
        setSelectedTerritoryId(territoryId);
        setSelectedTimeZone(currentTimeZone);
        fetchTimeSlots(territoryId, updatedSelectedObjectsArray, currentStore);
        if (isUpdate && updatedSelectedObjectsArray[0]) {
          setSelectedStore(updatedSelectedObjectsArray[0].value);
        }
      }
    }
    else {
      setStoresList([]);
    }
  };

  const getCountriesAndStores = async () => {
    setIsLoading(true);
    const responseCountries = await getCountriesStore();
    if (responseCountries.success === true) {
      const countryLabel = appointmentParams && appointmentParams.country ? appointmentParams.country : clientCountryCode ? countryCodeIndex[clientCountryCode] : undefined;
      const responseCountriesData = responseCountries.data;
      const countriesList = setCountriesListData(responseCountriesData);
      if (countriesList && responseCountriesData) {
        if (countryLabel) {
          const firstCountry = countriesList.find((country) => country.label === countryLabel);
          const firstCountryKey = countryLabel;
          if (firstCountry && firstCountry.value && firstCountry.value.length > 0) {
            setSelectedTimeZone(firstCountry.value[0].TimeZone);
            setSelectedCountry(firstCountry.label);
            setSelectedCountryCode(firstCountry.label);
            let currentStore;
            if (appointmentParams && appointmentParams.store) {
              const firstStore = firstCountry.value.find((store) => store.Id === appointmentParams.store);
              currentStore = firstStore.Id;
            }
            else if (firstCountry.value.length > 0) {
              currentStore = firstCountry.value[0].Id;
            }
            setSelectedStore(currentStore);
            setStoresListData(firstCountryKey, countriesList, currentStore);
          }
        }
      }
    }
    setIsLoading(false);
  };

  const setCountriesListData = countriesList => {
    let countriesOptions = [];
    Object.keys(countriesList).forEach((key, index) => {
      countriesOptions.push({
        value: countriesList[key],
        label: key
      });
    });
    const sortStores = (stores, storeCode) => {
      const matchingIndex = stores.findIndex(store => store.StoreCode === storeCode);

      if (matchingIndex !== -1) {
        // Case 1: If there is a storeCode in the stores.StoreCode list that matches, sort by a.StoreCode === storeCode
        return stores.slice().sort((a, b) => {
          if (a.StoreCode === storeCode && b.StoreCode !== storeCode) return -1;
          if (b.StoreCode === storeCode && a.StoreCode !== storeCode) return 1;
          return 0;
        });
      } else {
        // Case 2: If there are no stores.StoreCode properties matching the storeCode, sort by IsFlagship
        return stores.slice().sort((a, b) => b.IsFlagship - a.IsFlagship);
      }
    };
    [...countriesOptions].forEach((country) => {
      country.value = storeCode ? sortStores(country.value, storeCode) : [...country.value].sort((a, b) => b.IsFlagship - a.IsFlagship);
    });
    setCountriesList(countriesOptions);
    return countriesOptions;
  };

  const handleRef = useCallback((name, ref) => {
    inputRefs.current[name] = ref;
  }, []);

  const handleModalityChange = useCallback((selectedModality) => {
    setModality(selectedModality);
    setIsConfirmButtonEnable(true);
  }, []);

  const handleCancelButtonClicked = useCallback(() => {
    if (isUpdate && isConfirmButtonEnable) {
      setIsShowModalLeave(true);
    }
    else {
      history.push({
        pathname: isUpdate ? '/appointmentResume' : '/appointmentList'
      });
    }
  }, [history, isConfirmButtonEnable, isUpdate]);

  const createFormData = useCallback(() => {
    const selectedOptions = storesList.find(option => option.value === inputRefs.current.store.value);
    let selectedCountry;
    if (selectedOptions) {
      selectedCountry = countriesList.find(country => {
        return country.value.some(val => val.Id === selectedOptions.value);
      });
    }
    const dateTimeSlot = timeSlots[selectedDateFormat] || [];
    const timeSlot = dateTimeSlot.find(timeSlot => {
      return isSamedates(timeSlot.startTime, inputRefs.current.time.value)
    });
    const subjectPosition1 = subjectOptions.find(option => option.position === 1);
    const formValues = {
      subject: subject || subjectPosition1.value,
      comment: inputRefs.current.comment.value,
      modality: modality,
      storeLabel: selectedOptions && selectedOptions.label,
      store: selectedOptions && selectedOptions.value,
      country: selectedCountry && selectedCountry.label,
      startTime: timeSlot && timeSlot.startTime,
      endTime: timeSlot && timeSlot.endTime,
      selectedTimeZone: selectedTimeZone,
      lng: lng
    };
    if (isUpdate) {
      formValues.uuid = appointmentParams.uuid;
      formValues.isAppointmentDone = true;
    }
    return formValues;
  }, [countriesList, modality, selectedDateFormat, selectedTimeZone, storesList, timeSlots, subject, lng]);

  const createNewAppointment = useCallback(async (formValues) => {
    setIsLoading(true);
    try {
      const response = await createAppointment(formValues);
      if (response.success === true) {
        setIsLoading(false);
        if (response.data.data.status && response.data.data.status === "error") {
          setInputIsHidden(false);
        }
        else if (response.data.data.status && response.data.status === "insert failed") {
          setErrorMsgMaintenance({ title: response.data.status, body: response.data.errorReason });
          setIsShowModalMaintenance(true);
          setErrorMsg(response.data.data.errorReason);
          setInputIsHidden(false);
        }
        else if (response.data.data.status && errorStatuses.includes(response.data.data.status)) {
          setErrorMsg(response.data.data.errorReason);
          setInputIsHidden(false);
        }
        else {
          if (isAuth || isUpdate) {
            setInputIsHidden(true);
            history.push({
              pathname: '/appointmentResume',
              state: formValues
            });
          }
        }
      }
      else {
        setInputIsHidden(true);
      }
      setIsLoading(false);
    }
    catch (error) {
      setIsLoading(false);
      setInputIsHidden(true);
    }
  }, [isAuth, history, errorStatuses]);

  const checkDataValidity = useCallback(() => {
    let formIsValid = true;
    setIsFormSubmitted(true);
    const formValues = createFormData();
    const newValidationState = {
      countryError: isEmpty(formValues.country),
      startTimeError: isEmpty(formValues.startTime),
      ...(subject === 'Other services' ? { commentError: isEmpty(formValues.comment) } : {})
    };
    setValidationState(newValidationState);
    formIsValid = !Object.values(newValidationState).some(error => error);
    if (formIsValid) {
      setEvent('BAA_cta_next_button_clicked');
      if (isAuth || isUpdate) {
        formValues.accountId = clientId
        createNewAppointment(formValues);
      }
      else {
        dispatch(configActions.setAppointmentParams(formValues));
        history.push({
          pathname: '/contactDetails'
        });
      }
    }
  }, [clientId, isAuth, createNewAppointment, dispatch, history, createFormData]);

  const checkValidationForm = () => {
    const newValidationState = {
      ...validationState,
      ...(subject === 'Other services' ? { commentError: isEmpty(inputRefs.current.comment.value) } : {})
    };
    setValidationState(newValidationState);
  };

  const handleSubjectOptionChange = useCallback((name, value) => {
    setIsConfirmButtonEnable(true);
    setSubject(value.value);
    checkValidationForm();
  }, []);

  const handleCommentChange = useCallback((name, value) => {
    setIsConfirmButtonEnable(true);
  }, []);

  const handleCountryOptionChange = useCallback((name, value) => {
    setIsConfirmButtonEnable(true);
    checkIfShowLateModale(value);
    setSelectedCountryCode(value);
    setStoresListData(value);
    resetSelectedTime();
  }, [setStoresListData]);

  const handleStoreOptionChange = useCallback((name, value) => {
    setIsConfirmButtonEnable(true);
    const selectedOptions = storesList.find(option => option.value === value.value);
    const selectedCountry = countriesList.find(country => {
      return country.value.some(store => store.Id === selectedOptions.value);
    });
    const selectedStore = selectedCountry.value.find(store => store.Id === selectedOptions.value);
    const selectedTimeZone = selectedStore.TimeZone;
    setSelectedTimeZone(selectedTimeZone);
    setSelectedStore(selectedStore.Id);
    setSelectedTerritoryId(selectedOptions.value);
    fetchTimeSlots(selectedOptions.value);
    resetSelectedTime();
  }, [storesList, countriesList, fetchTimeSlots]);

  const handleTimeOptionChange = useCallback((name, value) => {
    setIsConfirmButtonEnable(true);
  }, []);

  const handleCalendarDropDownChange = useCallback((name, value) => {
    filterTimeSlots(value);
    setIsConfirmButtonEnable(true);
    resetSelectedTime();
  }, [filterTimeSlots]);

  const handleConfirmButtonClicked = useCallback(() => {
    checkDataValidity();
  }, [checkDataValidity]);

  const handleContinueToContactDetailButtonClicked = useCallback(() => {
    checkDataValidity();
  }, [checkDataValidity]);

  const checkIfShowLateModale = selectedCountry => {
    if (selectedCountry === 'Japan') {
      setIsShowModalLate(true);
    }
    else {
      setIsShowModalLate(false);
    }
  };

  const handleCloseModalLate = useCallback(() => {
    setIsShowModalLate(false);
  }, [checkDataValidity]);

  const handleCloseModalMaintenance = useCallback(() => {
    setIsShowModalMaintenance(false);
  }, []);

  const confirmButtonTitle = () => {
    if (isAuth) {
      return 'CONFIRM THIS APPOINTMENT';
    }
    else if (isUpdate) {
      return 'SAVES CHANGES';
    }
    else {
      return 'CONTINUE TO CONTACT DETAILS';
    }
  };

  const confirmButtonAction = () => {
    if (isAuth || isUpdate) {
      handleConfirmButtonClicked();
    }
    else {
      handleContinueToContactDetailButtonClicked()
    }
  };

  const handleConfirmModalLeave = useCallback(() => {
    setIsShowModalLeave(false);
  }, []);

  const handleCancelModalLeave = useCallback(() => {
    setIsShowModalLeave(false);
    history.push({
      pathname: isUpdate ? '/appointmentResume' : '/appointmentList'
    });
  }, []);

  return <div className={styles.container}>
    <LoadingOverlay isLoading={isRefreshing} />
    <div className={styles.containerForm}>
      <div className={styles.titleLabelContainer}>
        <p className={styles.titleLabel}>{t(isUpdate ? 'EDIT THE APPOINTMENT' : 'BOOK AN APPOINTMENT')}</p>
        <p className={styles.titleSubLabel}>{t("All the fields are mandatory")}</p>
      </div>
      {isLoading || countriesList.length === 0 ? <Loading /> :
        <>{!isAuth && <div className={styles.titleLabelSectionContainer}>
          <p className={styles.titleLabelSectionOptional}>1. {t("APPOINTMENT DETAILS")}</p>
        </div>}
          <div className={styles.titleLabelSectionContainer}>
            <p className={styles.titleLabelSection}>{t("REASON OF YOUR VISIT")}</p>
          </div>
          <div className={styles.dropDownContainerNotDivided}>
            <SimpleDropDown name="subject" options={subjectOptions || []} title={t("")} preselectedValue={subject || undefined} handleRef={handleRef} handleChange={handleSubjectOptionChange} returnValue={true} />
          </div>
          <SimpleTextArea title={t("COMMENTS")} name='comment' preselectedValue={appointmentParams.comment || undefined} placeholder={t("Please write if you have complementary comments")} handleRef={handleRef} handleChange={handleCommentChange} validationError={isFormSubmitted && validationState.commentError} />
          <div className={lng === "ar" ? styles.dropDownContainerRTL : styles.dropDownContainerLTR}>
            <SimpleDropDown name="country" options={countriesList} title={t("COUNTRY / REGION")} preselectedValue={selectedCountry} handleRef={handleRef} handleChange={handleCountryOptionChange} isEmptyValue={selectedCountry ? false : true} validationError={isFormSubmitted && validationState.countryError} />
            <SimpleDropDown name="store" options={storesList} title={t("STORE")} preselectedValue={selectedStore} handleRef={handleRef} handleChange={handleStoreOptionChange} returnValue={true} />
          </div>
          <div className={styles.dropDownParentContainer}>
            <div className={lng === "ar" ? styles.dropDownContainerRTL : styles.dropDownContainerLTR}>
              <CalendarDropDown name="date" title={t("DATE")} preselectedValue={selectedTimeSlot || undefined} handleRef={handleRef} dateList={timeSlots} clientLanguage={lng} handleCalendarDropDownChange={handleCalendarDropDownChange} />
              <SimpleDropDown name="time" options={currentTimeSlots} title={t("TIME")} subTitle={selectedTimeZone} preselectedValue={selectedTime} handleRef={handleRef} handleChange={handleTimeOptionChange} returnValue={true} />
            </div>
            <InfosMessage isDisplay={isShowModalLate} clientLanguage={lng} />
            <CustomRadioButton name="modality" options={radiobuttonOptions} title={t("MODALITY")} preselectedValue={modality || undefined} handleRef={handleRef} handleRadioOptionChange={handleModalityChange} />
            {isAuth && <ErrorMessage message={errorMsg} isHidden={inputIsHidden} />}
            <div className={styles.buttonBlockContainer}>
              <ConfirmButton title={t(confirmButtonTitle())} handleButtonClicked={confirmButtonAction} isEnable={isConfirmButtonEnable} />
            </div>
            {isAuth || isUpdate && <CancelButton title={t(isUpdate ? 'CANCEL CHANGES' : 'BACK TO MY APPOINTMENTS')} handleButtonClicked={handleCancelButtonClicked} />}
            {!isAuth && !isUpdate && <div className={styles.titleLabelSectionContainerOptional}>
              <p className={styles.titleLabelSectionOptional}>2. {t("CONTACT DETAILS")}</p>
            </div>}
          </div>
          <ModalLeaveWithoutSaving open={isShowModalLeave} onClose={handleCloseModalLate} clientLanguage={lng} confirmButtonPressed={handleConfirmModalLeave} deleteButtonPressed={handleCancelModalLeave} />
          <ModalWebsiteInMaintenance open={isShowModalMaintenance} onClose={handleCloseModalMaintenance} clientLanguage={lng} title={errorMsgMaintenance.title} body={errorMsgMaintenance.body} />
        </>}
    </div>
  </div >
};

export default React.memo(AppointmentForm);