import React from 'react';
import styles from './SessionExpired.module.css';
import { useTranslation } from 'react-i18next';

const SessionExpired = ({ errorMsg }) => {
  const errorMessage = errorMsg ? errorMsg : 'Contact your contact Advisor for more details';

  const { t } = useTranslation();

  return <>
    <div className={styles.container}>
      <div>
        <p className={styles.title}>{t('Your session has expired')}</p>
      </div>
      <div>
        <p className={styles.description}>{t(errorMessage)}</p>
      </div>
    </div>
  </>
};

export default SessionExpired;