import React from 'react';
import styles from './AppointmentListTable.module.css';
import { useTranslation } from 'react-i18next';
import crossIcon from './../../../styles/Images/appointementListDesktop/cross.png';
import { getFomatDate, getFomatTime, getDateFormat, replaceCharacter } from '../../../utils/Utils';

const AppointmentListTable = ({ appointmentList, isNextAppointmentSelected, cancelAppoitmentClicked, clientLanguage }) => {
  const { t } = useTranslation();
  const format = getFomatDate(clientLanguage);
  const formatTime = getFomatTime(clientLanguage);

  return <>
    {appointmentList && appointmentList.length > 0 && appointmentList.map(appointment => {
      const schedStartDate = new Date(appointment.startDateTime);
      const timezone = replaceCharacter('_', ' ', appointment.timeZone);
      return (
        <tr key={appointment.uuid}>
          <td>{getDateFormat(schedStartDate, format, appointment.timeZone)}</td>
          <td>{getDateFormat(schedStartDate, formatTime, appointment.timeZone)} <span className={styles.timeZoneLabel}>({timezone})</span></td>
          <td className={styles.locationLabel}>{appointment.appointmentType === 'PEOPLE IN-PERSON' ? appointment.location + ' - ' + t('IN PERSON') : appointment.storeName + ' - ' + t(appointment.location)}</td>
          <td>{t(appointment.appointmentReason)}</td>
          {isNextAppointmentSelected ? <td><div className={styles.cancelAppointmentContainer} onClick={() => cancelAppoitmentClicked(appointment)}>
            <div>
              <img src={crossIcon} alt="delete" className={clientLanguage === "ar" ? styles.crossIconRTL : styles.crossIconLTR}></img>
            </div>
            <div>
              <p className={styles.crossIconLabel}>{t("CANCEL APPOINTMENT")}</p>
            </div>
          </div>
          </td> : <td className={appointment.status === 'Completed' ? styles.showNoShowLabel : styles.showNoShowLabelRed}>{appointment.status === 'Completed' ? t("SHOW") : t("NO SHOW")}</td>}
        </tr>
      )
    })}
  </>
};

export default React.memo(AppointmentListTable);