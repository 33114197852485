import React from 'react';
import { useSelector } from 'react-redux';
import styles from './ModalWebsiteInMaintenance.module.css';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import closeCross from './../../../styles/Images/appointementListDesktop/closeCross.png';

const ModalWebsiteInMaintenance = ({ onClose, open, clientLanguage, title, body }) => {
    const { t } = useTranslation();
    const isUnderMaintenance = useSelector((state) => state.config.isUnderMaintenance);
    const screenWidth = window.innerWidth;
    const titleToDisplay = title || 'Service Unavailable';
    const bodyToDisplay = body || 'The site is currently under maintenance. Please check back later.';

    return <div>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='md'
            fullWidth={true}
            fullScreen={screenWidth < 500 ? true : false}
            PaperProps={{
                style: { borderRadius: 0 }
            }}
        >
            {!isUnderMaintenance && (
                <div className={clientLanguage === "ar" ? styles.closeCrossContainerRTL : styles.closeCrossContainerLTR}>
                    <img src={closeCross} alt="close" onClick={onClose} className={clientLanguage === "ar" ? styles.crossIconRTL : styles.crossIconLTR} />
                </div>
            )}
            <DialogTitle id="alert-dialog-title">
                <div className={styles.headerContainer}>
                    <p>{t(titleToDisplay)}</p>
                </div>
            </DialogTitle>
            <div className={styles.bodyContainer}>
                <p className={styles.bodyContainerLabel}>{t(bodyToDisplay)}</p>
            </div>
            {!isUnderMaintenance && <div className={styles.buttonContainer}>
                <div className={styles.buttonTopStyleCancel} onClick={onClose}>
                    <p className={styles.buttonTopStyleLabelCancel}>{t("Close")}</p>
                </div>
            </div>}
        </Dialog>
    </div>
};

export default React.memo(ModalWebsiteInMaintenance);