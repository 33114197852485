import React from 'react';
import { useSelector } from 'react-redux';
import Loading from './../components/Loading/Loading';
import Footer from '../components/Footer/Footer';
import styles from './Home.module.css';
import HeaderLogged from '../components/Header/HeaderLogged';
import HeaderGuest from '../components/Header/HeaderGuest';
import HeaderMobile from '../components/Header/HeaderMobile/HeaderMobile';

const LoadingPage = () => {

    const { isAuth } = useSelector((state) => state.config);
    const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
    return <>
        <div className={clientLanguage === "ar" ? styles.containerRTL : styles.containerLTR}>
            <HeaderMobile clientLanguage={clientLanguage} />{isAuth ? <HeaderLogged clientLanguage={clientLanguage} /> : <HeaderGuest clientLanguage={clientLanguage} />}
            <Loading isShowTitle={false} />
        </div>
        <div>
            <Footer />
        </div>
    </>;
}

export default LoadingPage;
