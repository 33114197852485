import React from 'react';
import styles from './ModalLeaveWithoutSaving.module.css';
import { useTranslation } from 'react-i18next';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import closeCross from './../../../styles/Images/appointementListDesktop/closeCross.png';

const ModalLeaveWithoutSaving = ({ onClose, open, clientLanguage, confirmButtonPressed, deleteButtonPressed }) => {
    const { t } = useTranslation();

    const screenWidth = window.innerWidth;
    const title = 'LEAVE WITHOUT SAVING';
    const bodyPart1 = 'By leaving now, your changes won’t be saved';
    const bodyPart2 = 'What would you like to do?';

    return <div>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth='md'
            fullWidth={true}
            fullScreen={screenWidth < 500 ? true : false}
            PaperProps={{
                style: { borderRadius: 0 }
            }}
        >
            <div className={clientLanguage === "ar" ? styles.closeCrossContainerRTL : styles.closeCrossContainerLTR}>
                <img src={closeCross} alt="close" onClick={onClose} className={clientLanguage === "ar" ? styles.crossIconRTL : styles.crossIconLTR}></img>
            </div>
            <DialogTitle id="alert-dialog-title">
                <div className={styles.headerContainer}>
                    <p>{t(title)}</p>
                </div>
            </DialogTitle>
            <div className={styles.bodyContainer}>
                <p className={styles.bodyContainerLabel}>{t(bodyPart1)}.</p>
                <p className={styles.bodyContainerLabel}>{t(bodyPart2)}</p>
            </div>
            <div className={styles.buttonContainer}>
                <div className={styles.buttonTopStyle} onClick={() => confirmButtonPressed()}>
                    <p className={styles.buttonTopStyleLabel}>{t("KEEP EDITING")}</p>
                </div>
                <div className={styles.buttonTopStyleCancel} onClick={() => deleteButtonPressed()}>
                    <p className={styles.buttonTopStyleLabelCancel}>{t("DELETE CHANGES")}</p>
                </div>
            </div>
        </Dialog>
    </div>
};

export default React.memo(ModalLeaveWithoutSaving);