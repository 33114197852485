import React, { useState, useCallback } from 'react';
import styles from './AppointmentList.module.css';
import { useTranslation } from 'react-i18next';
import AppointmentTabs from './../AppointmentTabs/AppointmentTabs';
import refresh from './../../../styles/Images/appointementListDesktop/refresh.png';
import AppointmentListContent from './../AppointmentListContent/AppointmentListContent';

const AppointmentList = ({ handleClickOpen, setCurrentAppointment, pastAppointmentList, nextAppointmentList, refreshAppointmentList, clientLanguage, isLoadingList }) => {

  const { t } = useTranslation();
  const [isNextAppointmentSelected, setIsNextAppointmentSelected] = useState(true);
  const currentList = isNextAppointmentSelected ? nextAppointmentList : pastAppointmentList;

  const cancelAppoitmentClicked = useCallback((appointment) => {
    setCurrentAppointment(appointment);
    handleClickOpen(appointment);
  }, [handleClickOpen, setCurrentAppointment]);

  return <>
    <AppointmentTabs clientLanguage={clientLanguage} isNextAppointmentSelected={isNextAppointmentSelected} setIsNextAppointmentSelected={setIsNextAppointmentSelected} />
    <div className={clientLanguage === "ar" ? styles.refreshContainerRTL : styles.refreshContainerLTR}>
      <div className={clientLanguage === "ar" ? styles.logoContainerRTL : styles.logoContainerLTR} onClick={refreshAppointmentList}>
        <img src={refresh} alt="Refresh" className={styles.logo}></img>
      </div>
      <div onClick={refreshAppointmentList}>
        <p className={styles.refreshListLabel}>{t("REFRESH LIST")}</p>
      </div>
    </div>
    <AppointmentListContent isLoadingList={isLoadingList} currentList={currentList} isNextAppointmentSelected={isNextAppointmentSelected} cancelAppoitmentClicked={cancelAppoitmentClicked} clientLanguage={clientLanguage} />
  </>
};

export default React.memo(AppointmentList);