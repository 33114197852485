import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import styles from './CalendarDropDown.module.css';
import './CalendarStyle.css';
import Calendar from 'react-calendar';
import moment from 'moment';
import TitleSection from '../TitleSection/TitleSection';
import calendar from '../../styles/Images/createAppointment/form/calendar.png';
import { getDateFormatNoTZ, getFomatDateShort, getLastItemOfArray, getUniqueListOfObjectByDate, getFirstNCharacters, setLocalDate } from '../../utils/Utils';

const CalendarDropDown = ({ title, handleCalendarDropDownChange, name, handleRef, dateList, preselectedValue }) => {
  const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [enabledDates, setEnabledDates] = useState([]);
  const format = getFomatDateShort(clientLanguage);
  const today = new Date();
  const endDateMoment = moment(today);
  const maxMonth = endDateMoment.add(61, 'days').toDate();
  const calendarContainerRef = useRef(null);
  const calendarInputContainerRef = useRef(null);
  const calendarType = clientLanguage === "ar" ? "islamic" : undefined;

  useEffect(() => {
    const uniqueDates = getUniqueListOfObjectByDate(dateList);
    setEnabledDates(uniqueDates);
    const lastKey = getLastItemOfArray(uniqueDates);
    setSelectedDate(lastKey);
    if (preselectedValue) {
      if (typeof preselectedValue === 'string') {
        setSelectedDate(preselectedValue);
      } else if (moment.isMoment(preselectedValue)) {
        setSelectedDate(preselectedValue.format('YYYY-MM-DD'));
      }
    }
  }, [dateList, preselectedValue, clientLanguage]);

  const handleClickOutside = (event) => {
    if (calendarContainerRef.current && !calendarContainerRef.current.contains(event.target) && !calendarInputContainerRef.current.contains(event.target)) {
      setShowCalendar(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onChangeDate = (date) => {
    setSelectedDate(date);
    let currentDate;
    if (clientLanguage === "ar") {
      setLocalDate('en');
      currentDate = getDateFormatNoTZ(date, 'YYYY-MM-DD');
      setLocalDate('ar');
    }
    else {
      currentDate = getDateFormatNoTZ(date, 'YYYY-MM-DD');
    }
    handleCalendarDropDownChange(name, currentDate);
    setShowCalendar(false);
  };

  const onClickInput = () => {
    setShowCalendar((prevShowCalendar) => !prevShowCalendar);
  };

  const currentDate = () => {
    return <p>{getDateFormatNoTZ(selectedDate, format)}</p>;
  };

  const convertArabicNumbersToWestern = (str) => {
    const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];
    const westernNumbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
    let newStr = '';
    for (let i = 0; i < str.length; i++) {
      const index = arabicNumbers.indexOf(str[i]);
      newStr += index !== -1 ? westernNumbers[index] : str[i];
    }
    return newStr;
  };

  return (
    <div className={styles.container}>
      <TitleSection title={title} />
      <div
        className={styles.calendarInputContainer}
        onClick={onClickInput}
        ref={(el) => {
          handleRef(name, el);
          calendarInputContainerRef.current = el;
        }}
      >
        <div className={clientLanguage === "ar" ? styles.calendarContainerLabelRTL : styles.calendarContainerLabelLTR}>
          {currentDate()}
        </div>
        <div className={clientLanguage === "ar" ? styles.calendarContainerIconRTL : styles.calendarContainerIconLTR}>
          <img src={calendar} alt="Calendar" className={styles.calendarIcon}></img>
        </div>
      </div>
      {showCalendar && (
        <div className={clientLanguage === "ar" ? styles.calendarContainerRTL : styles.calendarContainerLTR} ref={calendarContainerRef}>
          <Calendar
            calendarType={calendarType}
            onChange={onChangeDate}
            locale={clientLanguage === "ar" ? "ar-u-nu-arab" : getFirstNCharacters(clientLanguage, 2)}
            value={selectedDate ? new Date(selectedDate) : today}
            minDate={today}
            maxDate={maxMonth}
            tileDisabled={({ date }) => {
              const dateString = moment(date).format("YYYY-MM-DD");
              if (clientLanguage === "ar") {
                const westernDateString = convertArabicNumbersToWestern(dateString);
                return !enabledDates.includes(westernDateString);
              }
              return !enabledDates.includes(dateString);
            }}
            tileClassName={({ date, view }) => enabledDates.includes(moment(date).format("YYYY-MM-DD")) ? 'enabled' : null}
          />
        </div>
      )}
    </div>
  );
};

export default React.memo(CalendarDropDown);
