import { useDispatch } from 'react-redux';
import { configActions } from './../store/config';
import { useTranslation } from 'react-i18next';
import { setLocalDate } from './../utils/Utils';

const useChangeLanguage = () => {
    const { i18n } = useTranslation();
    const dispatch = useDispatch();

    const changeLanguage = (language = 'en_US') => {
        console.log('changeLanguage hook: ', language);
        setLocalDate(language);
        dispatch(configActions.setCurrentLanguage(language));
        i18n.changeLanguage(language, (err, t) => {
            if (err) return console.log('something went wrong loading', err);
        });
    };
    return changeLanguage;
};

export default useChangeLanguage;
