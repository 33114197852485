import React from 'react';
import styles from './HeaderGuest.module.css';
import { useSelector } from 'react-redux';
import myProfile from './../../styles/Images/headerDesktop/myProfile.png';

const HeaderGuest = () => {

  const isAuth = useSelector((state) => state.config.isAuth);
  const { returnHomeUrl } = useSelector((state) => state.config.clientInfos);
  const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
  const homeUrl = returnHomeUrl || 'https://www.chaumet.com';

  return <div className={styles.headerContent}>
    <div className={clientLanguage === "ar" ? styles.logoContainerRTL : styles.logoContainerLTR}>
      <a href={homeUrl} ><img src="assets/logoMain.png" alt="Chaumet" className={clientLanguage === "ar" ? styles.logoRTL : styles.logoLTR}></img></a>
    </div>
    <div className={clientLanguage === "ar" ? styles.arrowBackRightSpaceRTL : styles.arrowBackRightSpaceLTR}>
      {isAuth && <a href={homeUrl} ><img src={myProfile} alt="Chaumet" className={styles.myProfileIcon}></img></a>}
    </div>
  </div>

}

export default React.memo(HeaderGuest);