import React from 'react';
import styles from './BackgroudImage.module.css';
import { useTranslation } from 'react-i18next';

const BackgroudImage = ({ clientLanguage }) => {
  const { t } = useTranslation();

  return <div className={styles.backgroundImageContainer}>
    <img src="assets/headerBackground.png" alt="Chaumet" className={styles.backgroundImage}></img>
    {<p className={clientLanguage === "ar" ? styles.backgroundImageLabelRTL : styles.backgroundImageLabelLTR}>{t("MY APPOINTMENTS")}</p>}
  </div>
};

export default BackgroudImage;