import React, { useCallback, forwardRef, useState, useImperativeHandle } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styles from './AppointmentCGIRGPD.module.css';
import CustomRadioButton from '../../../UI/CustomRadioButton/CustomRadioButton';
import { radiobuttonYESNOOptions, privacyPolicyUrls } from '../../../services/data';
import { isEmpty } from '../../../utils/formUtils';

const AppointmentCGIRGPD = forwardRef((props, ref) => {
    const { t } = useTranslation();
    const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
    const privacyPolicyUrl = privacyPolicyUrls[clientLanguage] || privacyPolicyUrls.default;
    const [consentCGI, setConsentCGI] = useState('');
    const [consent, setConsent] = useState('');
    const [validationState, setValidationState] = useState({
        consentCGIError: false,
        consentError: false
    });

    const createFormData = () => {
        let formValues = {};
        formValues.consentCGI = consentCGI === 'true';
        formValues.consent = consent === 'true';
        return formValues;
    };

    const validateForm = () => {
        let formIsValid = true;
        const formValues = createFormData();
        const newValidationState = {
            consentCGIError: isEmpty(consentCGI),
            consentError: isEmpty(consent)
        };

        setValidationState(newValidationState);
        formIsValid = !Object.values(newValidationState).some(error => error);
        return { formValuesCGI: formValues, isFormIsValidCGI: formIsValid };
    };

    useImperativeHandle(ref, () => ({
        validateForm: validateForm,
    }));

    const handleConsentCGIChange = useCallback((selectedConsentCGI) => {
        setConsentCGI(selectedConsentCGI);
    }, []);

    const handleConsentChange = useCallback((selectedConsent) => {
        setConsent(selectedConsent);
    }, []);

    return <div className={styles.container}>
        <div className={clientLanguage === "ar" ? styles.containerInfosRTL : styles.containerInfosLTR}>
            <div><p className={styles.containerInfosLabel}>{t('I would like to receive information regarding Chaumet products, news, and events.')}</p></div>
            <div><CustomRadioButton name="consent" options={radiobuttonYESNOOptions} title={t("")} handleRadioOptionChange={handleConsentChange} isHorizontalOptions={true} optionIsEmpty={validationState.consentError} /></div>
        </div>
        <div className={styles.containerInfos}>
            <div><p className={styles.containerInfosLabel}>
                {t('I agree to the processing of my personal data in order to create my profile and personalise my experience, based on my preferences and purchasing data, which may be transmitted to LVMH, our parent company.')}
                <a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">{t('Read more')}</a>
            </p></div>
            <div><CustomRadioButton name="consentCGI" options={radiobuttonYESNOOptions} title={t("")} handleRadioOptionChange={handleConsentCGIChange} isHorizontalOptions={true} optionIsEmpty={validationState.consentCGIError} /></div>
        </div>
        <div className={styles.containerInfosDetailsTitleLine}>
        </div>
    </div>;
});

export default React.memo(AppointmentCGIRGPD);