import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './SimpleTextArea.module.css';
import TitleSection from './../TitleSection/TitleSection';
import ErrorMessage from './../../UI/ErrorMessage/ErrorMessage';
import { isValidString } from './../../utils/formUtils';

const SimpleTextArea = ({ title, name, placeholder, handleRef, handleChange, preselectedValue, isEmptyValue = false, validationError = false }) => {
    const { t } = useTranslation();

    const [selectedValue, setSelectedValue] = useState(isEmptyValue ? '' : preselectedValue);
    const [errorMsg, setErrorMsg] = useState('');
    const [inputIsHidden, setInputIsHidden] = useState(true);

    const handleOptionChange = event => {
        const selectedValue = event.target.value;
        setSelectedValue(selectedValue);
        handleChange && handleChange(name, selectedValue);
    }

    useEffect(() => {
        if (validationError && !selectedValue) {
            setErrorMsg('Please fill this field');
            setInputIsHidden(false);
        }
        else if (!isValidString(selectedValue)) {
            setErrorMsg('Input contains invalid characters');
            setInputIsHidden(false);
        } else {
            setErrorMsg('');
            setInputIsHidden(true);
        }
    }, [validationError, selectedValue]);

    return <div className={styles.container}>
        <TitleSection title={title} />
        <textarea name={name} placeholder={t(placeholder)} className={styles.inputStyle} ref={el => handleRef(name, el)} onChange={handleOptionChange} maxLength={254} value={selectedValue}></textarea>
        <ErrorMessage message={errorMsg} isHidden={inputIsHidden} />
    </div>;
};

export default React.memo(SimpleTextArea);