import React from 'react';
import styles from './Loading.module.css';
import { useTranslation } from 'react-i18next';

const Loading = ({ isShowTitle = true }) => {

  const { t } = useTranslation();

  return <>
    <div className={styles.container}>
      <div><img src="assets/logoMain.png" alt="logo" className={styles.logo}></img></div>
      <div><img src="assets/loader.gif" alt="logo" className={styles.icon}></img></div>
      {isShowTitle ? <h1 className={styles.title}>{t("Loading, Please wait …")}</h1> : ''}
    </div>
  </>
};

export default Loading;