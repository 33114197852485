import React from 'react';
import styles from './NoAppointments.module.css';
import { useTranslation } from 'react-i18next';
import calendarIcon from './../../../styles/Images/appointementListDesktop/calendarIcon.png';

  const NoAppointments = props => {
    const { isNextAppointmentSelected } = props;
    const { t, i18n } = useTranslation();
      
      return <>
      <div className={styles.container}>
        <div>
            <img src={calendarIcon} alt="calendarIcon" className={styles.calendarIcon}></img>
        </div>
        <div>
            <p className={styles.title}>{t(isNextAppointmentSelected ? 'NO INCOMING APPOINTMENTS' : 'NO PAST APPOINTMENTS')}</p>
          </div>
          <div>
            <p className={styles.description}>{t(isNextAppointmentSelected ? 'Your next appointments will appear here.' : 'Your past appointments will appear here.')}</p> 
            <p className={styles.description}>{t('Book a new appointment so we meet soon.')}</p> 
        </div>
     </div>
        </>
  };

  export default React.memo(NoAppointments);