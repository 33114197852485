export const checkEmptyFields = formData => {
    const missingFields = Object.entries(formData).reduce((acc, [field, value]) => {
        if (undefined || value === '' || value === 'noValue' || value === ' ') acc[field] = value;
        return acc;
    }, {});
    return missingFields;
};

const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

const phoneRegex = /^\d+$/;

export const isOnlyNumber = phone => {
    if (!phoneRegex.test(phone)) {
        return false
    }
    return true;
};

export const isEmailValid = email => {
    if (!emailRegex.test(email)) {
        return false
    }
    return true;
};

export const isEmpty = text => {
    return text === undefined || text === null || (typeof text === 'string' && text.trim() === '');
};

export const isValidString = text => {
    const regex = /[<>[\]{}*|]/;
    return !regex.test(text);
};