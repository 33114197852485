import React, { useEffect } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Home from './../pages/Home';
import GuestContactDetails from './../pages/GuestContactDetails';
import AppointmentResume from './../pages/AppointmentResume';
import CancelAppointment from './../pages/CancelAppointment';
import LoggedAppointmentList from './../pages/LoggedAppointmentList';
import AddToMyCalendar from './../pages/AddToMyCalendar';

const AppRoutes = () => {
    const history = useHistory();
    const location = useLocation();
    const isAuth = useSelector((state) => state.config.isAuth);

    useEffect(() => {
        const handlePopState = () => {
            const currentPath = location.pathname;
            if (!isAuth && currentPath === '/appointmentResume') {
                history.replace('/');
            }
        };

        window.onpopstate = handlePopState;

        return () => {
            window.onpopstate = null;
        };
    }, [history, location, isAuth]);

    return (
        <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/addtocalendar" component={AddToMyCalendar} />
            <Route exact path="/appointmentList" component={LoggedAppointmentList} />
            <Route exact path="/contactDetails" component={GuestContactDetails} />
            <Route exact path="/appointmentResume" component={AppointmentResume} />
            <Route exact path="/appointmentCancel" component={CancelAppointment} />
        </Switch>
    );
};

export default AppRoutes;
