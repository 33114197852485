import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CancelButton.module.css';

const CancelButton = ({ title, handleButtonClicked }) => {
  const { t } = useTranslation();

  return <div className={styles.container} onClick={handleButtonClicked}>
    <p className={styles.titleLabel}>{t(title)}</p>
  </div>;
};

export default React.memo(CancelButton);