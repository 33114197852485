import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DetailInfoItem.module.css';
import { replaceCharacter } from './../../utils/Utils';

const DetailInfoItem = ({ title, subtitle, value, withMargin = true }) => {
    const { t } = useTranslation();
    let fixedSubtitle = subtitle;
    if (subtitle) {
        fixedSubtitle = replaceCharacter('_', ' ', subtitle);
    }

    return <div className={styles.container}>
        <div className={withMargin ? styles.containerInfo : styles.containerInfoNoMargin}>
            <p className={styles.infoTitle}>{t(title)} {fixedSubtitle !== undefined ? fixedSubtitle.toUpperCase() : ''}</p>
        </div>
        <div className={withMargin ? styles.containerInfo : styles.containerInfoNoMargin}>
            <p className={styles.infoValue}>{t(value)}</p>
        </div>
    </div>;
};

export default React.memo(DetailInfoItem);