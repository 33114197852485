import React from 'react';
import styles from './LoadingOverlay.module.css';

const LoadingOverlay = ({ isLoading }) => {
  return (
    <>
      {isLoading && (
        <div className={styles.overlay}>
          <div className={styles.loader}></div>
        </div>
      )}
    </>
  );
};

export default LoadingOverlay;