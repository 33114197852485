import { useEffect, useCallback } from 'react';

// const useBackButtonHandler = (backButtonCallback) => {
//     useEffect(() => {
//         const handleBackButton = () => {
//             backButtonCallback();
//         };
//         window.addEventListener('popstate', handleBackButton);
//         return () => {
//             window.removeEventListener('popstate', handleBackButton);
//         };
//     }, [backButtonCallback]);
// };

// export default useBackButtonHandler;

const useBackButtonHandler = (backButtonCallback) => {
    const handleBackButton = useCallback(() => {
        backButtonCallback();
    }, [backButtonCallback]);

    useEffect(() => {
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [backButtonCallback, handleBackButton]);

    // Return the function to remove the listener
    return () => {
        window.removeEventListener('popstate', handleBackButton);
    };
};

export default useBackButtonHandler;