import React, { useState, useRef, forwardRef, useImperativeHandle } from 'react';
import styles from './ReCaptcha.module.css';
import ReCAPTCHA from 'react-google-recaptcha';
import ErrorMessage from '../../UI/ErrorMessage/ErrorMessage';
import { CAPTCHA_PUBLIC_KEY } from './../../services/data';

const ReCaptcha = forwardRef((props, ref) => {
    const captchaRef = useRef(null);
    const [errorMsg, setErrorMsg] = useState("");
    const [inputIsHidden, setInputIsHidden] = useState(true);

    const onChange = async token => {
        setInputIsHidden(true);
    };

    const handleSubmit = async () => {
        let token = captchaRef.current.getValue();
        if (!token) {
            setErrorMsg("You must confirm you are not a robot");
            setInputIsHidden(false);
        }
        return { isFormIsValidCaptcha: true, token: token };
    };

    useImperativeHandle(ref, () => ({ handleSubmit }));

    return <div className={styles.container} >
        <ReCAPTCHA sitekey={CAPTCHA_PUBLIC_KEY} ref={captchaRef} onChange={onChange} size='normal' />
        <ErrorMessage message={errorMsg} isHidden={inputIsHidden} />
    </div >;
});

export default React.memo(ReCaptcha);
