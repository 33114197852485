import { analytics, setUserProperties, logEvent } from './../services/firebase';

export const setEvent = (eventName, eventParams) => {
    if (analytics) {
        logEvent(analytics, eventName, eventParams);
    } else {
        console.warn("Firebase Analytics n'est pas initialisé. L'événement n'a pas été enregistré.");
    }
};

export const setUserLoginStatus = isLoggedIn => {
    if (analytics) {
        setUserProperties(analytics, { isLoggedIn: isLoggedIn });
    } else {
        console.warn("Firebase Analytics n'est pas initialisé. La UserProperty n'a pas été définie.");
    }
};