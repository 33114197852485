import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styles from './Footer.module.css';
import { useTranslation } from 'react-i18next';
import instagram from './../../styles/Images/instagram.png';
import facebook from './../../styles/Images/facebook.png';
import youtube from './../../styles/Images/youtube.png';
import twitter from './../../styles/Images/twitter.png';
import pinterest from './../../styles/Images/pinterest.png';
import wechat from './../../styles/Images/wechat.png';
import line from './../../styles/Images/line.png';
import linkedin from './../../styles/Images/linkedin.png';
import kakaotalk from './../../styles/Images/kakaotalk.png';
import chineseIM from './../../styles/Images/chineseIM.png';
import subscribe from './../../styles/Images/subscribe.png';
import contactUs from './../../styles/Images/contactUs.png';
import findBoutique from './../../styles/Images/findBoutique.png';
import useChangeLanguage from './../../hooks/useChangeLanguage';
import { optionsTranslations } from './../../services/data';

const Footer = () => {

  const { t } = useTranslation();
  const clientLanguage = useSelector((state) => state.config.lng || 'en_US');
  const changeLanguage = useChangeLanguage();

  useEffect(() => {
  }, [clientLanguage]);

  const handleLanguageChange = event => {
    changeLanguage(event.target.value);
  };

  const goToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return <>
    <div className={clientLanguage === "ar" ? styles.footerContentRTL : styles.footerContentLTR}>
      <div className={styles.footerHeader}>
        <div className={clientLanguage === "ar" ? styles.leftBlockRTL : styles.leftBlock}>
          <div className={styles.newsletterBlock}>
            <p className={styles.newsletterBlockTitle}>{t("Newsletter")}</p>
            <p className={styles.newsletterBlockDescription}>{t("Latest from chaumet")}</p>
          </div>
          <div className={styles.subscribeBlock}>
            <div><p><a href="https://preprod-ecomm.chaumet.com/fr_fr/abonnement-newsletter" target="_blank" rel="noopener noreferrer">{t("Subscribe")}</a></p></div>
            <div><img src={subscribe} alt="Subscribe" className={styles.logo}></img></div>
          </div>
        </div>
        <div className={styles.rightBlock}>
          <div className={styles.contactUsBlock}>
            <a href="https://preprod-ecomm.chaumet.com/fr_fr/contact" target="_blank" rel="noopener noreferrer">
              <div className={styles.contactUsBlockIn}>
                <div className={clientLanguage === "ar" ? styles.contactUsBlockLogoRTL : styles.contactUsBlockLogo}><img src={contactUs} alt="Contact Us" className={styles.logo}></img></div>
                <div className={styles.contactUsBlockContainer}>
                  <div><p className={styles.contactUsBlockTitle}>{t("Contact")}</p></div>
                  <div><p className={styles.contactUsBlockDescription}>{t("US")}</p></div>
                </div>
              </div>
            </a>
          </div>
          <div className={styles.findBoutiqueBlock}>
            <a href="https://stores.chaumet.com/fr/search" target="_blank" rel="noopener noreferrer">
              <div className={styles.findBoutiqueBlockIn}>
                <div className={clientLanguage === "ar" ? styles.contactUsBlockLogoRTL : styles.contactUsBlockLogo}><img src={findBoutique} alt="Contact Us" className={styles.logo}></img></div>
                <div className={styles.contactUsBlockContainer}>
                  <div><p className={styles.contactUsBlockTitle}>{t("Find a")}</p></div>
                  <div><p className={styles.contactUsBlockDescription}>{t("Boutique")}</p></div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className={styles.footerSocialnetwork}>
        <div className={styles.socialNetworkTitle}>
          <p>{t("Follow us")}</p>
        </div>
        <div className={styles.footerLinkContainer}>
          <div className={styles.footerLink}>
            <div className={styles.footerLinkItem}><a href="https://www.instagram.com/chaumetofficial" target="_blank" rel="noopener noreferrer"><img src={instagram} alt="Instagram" className={styles.logo}></img></a></div>
            <div className={styles.footerLinkItem}><a href="https://www.facebook.com/Chaumet" target="_blank" rel="noopener noreferrer"><img src={facebook} alt="Facebook" className={styles.logo}></img></a></div>
            <div className={styles.footerLinkItem}><a href="https://www.youtube.com/user/Chaumet" target="_blank" rel="noopener noreferrer"><img src={youtube} alt="Youtube" className={styles.logo}></img></a></div>
            <div className={styles.footerLinkItem}><a href="https://twitter.com/Chaumet" target="_blank" rel="noopener noreferrer"><img src={twitter} alt="Twitter" className={styles.logo}></img></a></div>
            <div className={styles.footerLinkItem}><a href="https://pinterest.com/chaumet" target="_blank" rel="noopener noreferrer"><img src={pinterest} alt="Pinterest" className={styles.logo}></img></a></div>
            <div className={styles.footerLinkItem}><a rel="noopener noreferrer"><img src={wechat} alt="Wechat" className={styles.logo}></img></a></div>
            <div className={styles.footerLinkItem}><a href="https://line.me/R/ti/p/%40702byybf" target="_blank" rel="noopener noreferrer"><img src={line} alt="Line" className={styles.logo}></img></a></div>
            <div className={styles.footerLinkItem}><a href="https://fr.linkedin.com/company/chaumet" target="_blank" rel="noopener noreferrer"><img src={linkedin} alt="Linkedin" className={styles.logo}></img></a></div>
            <div className={styles.footerLinkItem}><a href="http://pf.kakao.com/_BxgxmSK" target="_blank" rel="noopener noreferrer"><img src={kakaotalk} alt="Kakaotalk" className={styles.logo}></img></a></div>
            <div className={styles.footerLinkItem}><a href="https://www.xiaohongshu.com/user/profile/5f4d243a000000000101c786?xhsshare=CopyLink&appuid=5465e121d6e4a91ffab769dc&apptime=1632289750" target="_blank" rel="noopener noreferrer"><img src={chineseIM} alt="xiaohongshu" className={styles.logo}></img></a></div>
          </div>
        </div>
      </div>
      <div className={styles.footerExternalLinkContainer}>
        <div className={clientLanguage === "ar" ? styles.footerLanguagesRTL : styles.footerLanguages}>
          <div>
            <select name="language" value={clientLanguage} onChange={handleLanguageChange}>
              {optionsTranslations && optionsTranslations.map(language => <option value={language.value} key={language.value}>{language.label}</option>)}
            </select>
          </div>
          <div>
            <button className={clientLanguage === "ar" ? styles.footerLanguagesHanchorRTL : styles.footerLanguagesHanchor} onClick={goToTop}><img src="assets/arrowTop.svg" alt="Services" className={styles.arrowTop}></img></button>
          </div>
        </div>
      </div>
    </div>
  </>

}

export default React.memo(Footer);
