import React from 'react';
import styles from './LoaderRequest.module.css';
import { useTranslation } from 'react-i18next';
import Dialog from '@mui/material/Dialog';

  const LoaderRequest = props => {

    const { t, i18n } = useTranslation();
    const { onClose, open } = props;
      
      return <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth='md'
        fullWidth={true}
        PaperProps={{
            style: { borderRadius: 0 }
          }}
      >
      <div className={styles.container}>
        <div>
        <div><img src="assets/loader.gif" alt="logo" className={styles.loaderIcon}></img></div>
        </div>
        <div>
            <p className={styles.description}>{t('We are processing the request.')}</p> 
            <p className={styles.description}>{t('Thank you for your patience!')}</p> 
        </div>
     </div>
      </Dialog>
    </div>
  };

  export default React.memo(LoaderRequest);